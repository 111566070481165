import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DrawerComponent from "../Includes/Sidebar";
import { Button, Divider, TextField } from "@mui/material";
import { CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import a1 from '../../../avatar.png';
import a2 from '../../../avatar2.png';
import a3 from '../../../avatar3.png';
import a4 from '../../../avatar4.png';
import a5 from '../../../a.png'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import { updateDocDescription, deleteDocument, updateDocumentIndex, getAllUser ,updateUserAccess ,shareUser} from '../AdminApiHelper';
import { Snackbar, SnackbarContent } from '@mui/material';
const ActionMenu = (props) => {
    const {user,handleFetchDocuments,handleClearButtonClick} = props
    const [docDetails,setDocDetails] = useState(user)
    console.log('doc_id_user',user)
    const fetchDocuments = ()=>{
        handleFetchDocuments();
    }
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editDescriptionModalOpen, setEditDescriptionModalOpen] = useState(false);
    const [editUserAccessModalOpen, setEditUserAccessModalOpen] = useState(false);
    const [deleteDocModalOpen, setDeleteDocModalOpen] = useState(false)
    const [updateIndexModalOpen, setUpdateIndexModalOpen] = useState(false)
    const [status, setStatus] = useState('')
    const open = Boolean(anchorEl);
    const openEditDescriptionModal = () => {
        setEditDescriptionModalOpen(true)
    };
    const closeEditDescriptionModal = () => {
        setEditDescriptionModalOpen(false);
        setAnchorEl(null);
    };
    const openDeleteDocModal = () => {
        setDeleteDocModalOpen(true)
    };
    const closeDeleteDocModal = () => {
        setDeleteDocModalOpen(false);
        setAnchorEl(null);
    };
    const openUpdateIndexModal = () => {
        setUpdateIndexModalOpen(true)

    };
    const closeUpdateIndexModal = () => {
        setUpdateIndexModalOpen(false);
        setAnchorEl(null);
    };
    const openEditUserAccessModal = () => {
        handleShareToUser()
        setEditUserAccessModalOpen(true)

    };
    const closeEditUserAccessModal = () => {
        setEditUserAccessModalOpen(false);
        setAnchorEl(null);
    };
    const [doc_desc, setDoc_desc] = React.useState('');
    const [doc_id, setDoc_id] = React.useState('');
    const [shared_with, setShared_with] = React.useState([]);
    const [doc_name, setDoc_name] = React.useState('');
    const [indexStatus, setIndexStatus] = React.useState('');
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
        // if (user?.user?.doc_desc) {
            
        // }
        setDoc_desc(user.doc_desc);
        console.log('doc_id_prev', user.doc_id)
        setDoc_id(user.doc_id)
        setShared_with(user.shared_with)
        setDoc_name(user.doc_name)
        setIndexStatus(user.index_status)
        
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [shareTo, setShareTo] = useState([]);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastmessage, settoastmessage] = useState('');
    const [designation, setDesignation] = React.useState('');
    const [role, setRole] = React.useState('');
    const [department, setDepartment] = React.useState('');
    const handleChange = (event) => {
        setDesignation(event.target.value);
        setRole(event.target.value);
        setDepartment(event.target.value);
    };
    const [selectedItems, setSelectedItems] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            setSelectedItems([...selectedItems, inputValue.trim()]);
            setInputValue('');
        }
    };
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    

    const handleDelete = (itemToDelete) => {
        setSelectedItems((prevItems) => prevItems.filter((item) => item !== itemToDelete));
    };
    // const handleUpdateDesc=()=>{
    //     console.log('desc updated');
    //     closeEditDescriptionModal()
    // }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const fetchUserData = async () => {
        // setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const requestData = await getAllUser(deepuser_id, deeporg_id, userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                const newResults = requestData.data;
                setUserSearchResults(requestData.data);
                // setHasMore(requestData.data.length > 0);

                // setIsLoading(false)
                // setShowStaticContent(false);
            } else {
                // setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };
    const handleUpdateDesc = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            console.log('doc_id_next',doc_id)
            const responseData = await updateDocDescription(
                deepuser_id,
                deeporg_id,
                doc_id,
                doc_desc,
                userToken
            );

            if (responseData.code === "200") {

                setButtonLoading(false)
                fetchDocuments()
                closeEditDescriptionModal()
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
            } else {

                setButtonLoading(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const handleDeleteDocument = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            console.log('doc_id_next',doc_id)
            const responseData = await deleteDocument(
                deepuser_id,
                deeporg_id,
                doc_id,
                userToken
            );

            if (responseData.code === "200") {
                closeDeleteDocModal()
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                setButtonLoading(false)
                fetchDocuments()
            } else {
                setSnackbarOpen(true);
                settoastmessage(responseData.message);
                setButtonLoading(false)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const handleChangeIndex = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            console.log('doc_id_next',doc_id)
            const responseData = await updateDocumentIndex(
                deepuser_id,
                deeporg_id,
                doc_id,
                status,
                userToken
            );

            if (responseData.code === "200") {
                closeUpdateIndexModal()
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                setButtonLoading(false)
                fetchDocuments()
            } else {
                setSnackbarOpen(true);
                settoastmessage(responseData.message);
                setButtonLoading(false)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const handleUpdateUser = async (idsString) => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            console.log('doc_id_next',doc_id)
            const responseData = await updateUserAccess(
                deepuser_id,
                deeporg_id,
                doc_id,
                idsString,
                userToken
            );

            if (responseData.code === "200") {

                setButtonLoading(false)
                fetchDocuments()
                closeEditDescriptionModal()
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
            } else {

                setButtonLoading(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const handleShareToUser = async () => {
        
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const responseData = await shareUser(
                deepuser_id,
                deeporg_id,
                doc_id,
                userToken
            );

            if (responseData.code === "200") {
                setShareTo(responseData.data)
            } else {
                console.error("Error:", error);
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const [prevSharedWith, setPrevSharedWith] = useState([]);
    const [sharedIds, setSharedIds] = useState('');

const handleUpdateUserAccess = () => {
    // Extract the ids from the shared_with array
    const ids = shared_with.map(user => user.user_id);
    // Convert the array of ids into a comma-separated string
    const idsString = ids.join(',');
    // Merge the previous and new values into an array
    const mergedValues = [...prevSharedWith, ...shared_with];
    // Update the previous values to the current values for the next update
    setPrevSharedWith(shared_with);
    // Set the extracted ids in the state as a string
    setSharedIds(idsString);
    handleUpdateUser(idsString)
};
    return (
        <>
            <div>
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickMenu}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >

                    <Box>
                        <MenuItem onClick={openDeleteDocModal}>
                            Delete
                        </MenuItem>
                        <MenuItem onClick={openEditDescriptionModal}>
                            Edit Description
                        </MenuItem>
                        {indexStatus === 'indexed' && (
                            <MenuItem onClick={() => { openUpdateIndexModal(); setStatus('remove'); }}>
                                Remove Index
                            </MenuItem>
                        )} {indexStatus === 'no' && (
                            <MenuItem onClick={() => { openUpdateIndexModal(); setStatus('add'); }}>
                                Add Index
                            </MenuItem>
                        )}


                        <MenuItem onClick={openEditUserAccessModal}>
                            User Access
                        </MenuItem>
                    </Box>
                </Menu>
                <Dialog open={editDescriptionModalOpen} onClose={closeEditDescriptionModal}
                >
                    <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                        <Box sx={{ width: '400px' }}>
                            <Box display='flex' flexGrow={1}  >
                                <Box sx={{ width: '400px' }}>
                                    <strong>Update Description</strong>

                                </Box>
                                <Box >
                                    <IconButton>
                                        <CloseIcon onClick={closeEditDescriptionModal} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Divider />
                        </Box>
                    </DialogTitle>
                    <DialogContent >
                        <Box>
                            <Box sx={{ margin: '20px 0px' }}>Description <span style={{ color: 'red' }}>*</span> </Box>
                            <Box>
                                <TextField
                                    id="outlined-multiline-static"
                                    sx={{ width: '400px' }}
                                    multiline
                                    rows={3}
                                    value={doc_desc}
                                    onChange={(e) => setDoc_desc(e.target.value)}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'left', marginLeft: '15px', marginBottom: '15px' }}>
                        <Button color='primary' variant='contained' onClick={handleUpdateDesc} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                            {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}} />}  Update</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={editUserAccessModalOpen} onClose={closeEditUserAccessModal}
                >
                    <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex',justifyContent:'space-between' }} >
                        <Box sx={{ width: '100%' }}>
                            <strong>{doc_name} </strong>

                        </Box>
                        <Box >
                            <IconButton>
                                <CloseIcon onClick={closeEditUserAccessModal} />
                            </IconButton>
                        </Box>

                    </DialogTitle>
                    <DialogContent >
                        <Box>
                            <Divider sx={{ marginTop: '15px' }} />
                            <Box sx={{ margin: '20px 0px' }}>Select User <span style={{ color: 'red' }}>*</span> </Box>
                            <Box>
    {shareTo && (
        <Autocomplete
            multiple
            id="tags-outlined"
            options={shareTo.filter(option => !shared_with.some(user => user.user_id == option.user_id))}
            getOptionLabel={(option) => option.name}
            defaultValue={shared_with}
            onChange={(event, newValue) => setShared_with(newValue)}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select User"
                    placeholder="Add.."
                />
            )}
        />
    )}
</Box>

                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'left', marginLeft: '15px', marginBottom: '15px' }}>
                        <Button color='primary' variant='contained'onClick={handleUpdateUserAccess}backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                        {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}}/>}Update</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={deleteDocModalOpen} onClose={closeDeleteDocModal}
                >
                    <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                        <Box sx={{ width: '400px' }}>
                            <strong>Delete Confirmation</strong>

                        </Box>
                        <Box >
                            <IconButton>
                                <CloseIcon onClick={closeDeleteDocModal} />
                            </IconButton>
                        </Box>

                    </DialogTitle>
                    <DialogContent >
                        <Box>
                            Do you want to delete this document?
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'left', marginLeft: '15px', marginBottom: '15px' }}>
                        <Button color='primary' variant='contained' onClick={handleDeleteDocument} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                            {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}}/>}Delete</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={updateIndexModalOpen} onClose={closeUpdateIndexModal}
                >
                    <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                        <Box sx={{ width: '400px' }}>
                            <strong>Update Indextype</strong>

                        </Box>
                        <Box >
                            <IconButton>
                                <CloseIcon onClick={closeUpdateIndexModal} />
                            </IconButton>
                        </Box>

                    </DialogTitle>
                    <DialogContent >
                        <Box>
                            Do you want to update the indextype of document?
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'left', marginLeft: '15px', marginBottom: '15px' }}>
                        <Button color='primary' variant='contained' onClick={handleChangeIndex} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                            {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}} />}Update</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    mode="outlined"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center', // Center bottom
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <SnackbarContent
                        style={{
                            backgroundColor: 'black',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        message={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {toastmessage}
                            </span>
                        }
                        action={[
                            <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>

        </>
    )
}

export default ActionMenu;