import React, { createContext, useContext, useState } from 'react';

const PathContext = createContext();

export const PathProvider = ({ children }) => {
  const [currentPath, setCurrentPath] = useState('');

  return (
    <PathContext.Provider value={{ currentPath, setCurrentPath }}>
      {children}
    </PathContext.Provider>
  );
};

export const usePath = () => {
  return useContext(PathContext);
};