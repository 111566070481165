import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import {
  fetchQueryHistory,
  deleteQueryTitle,
  editQueryTitle,
} from "../ApiHelper/QuerySearchApi.js";
import './QueryHistory.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const BasicMenu = ({fetchData, items, showEditDialog}) => {
  const [loadingShareLink, setLoadingShareLink] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [deleteloading, setdeleteLoading] = useState(false);
  const [editloading, seteditloading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [sharelink, setsharelink] = useState(
    "https://www.deep-probe-mcl.zeonai.com/#/landing/shared-link?"
  );
  const [responsemsg, setresponsemsg] = useState('');
  const [editedQuery, setEditedQuery] = useState(items.query);
  const [isEditModal, setIsEditModal] = useState(false)
  const [isShareModal, setIsShareModal] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const openEditModal = () => {
    setIsEditModal(true);
    showEditDialog(items.query, items.conversation_id);
    console.log(items.query, items.conversation_id)
  }
  const closeEditModal = () => {
    setIsEditModal(false);
    handleClose();
  }
  const handleCopyToClipboard = () => {
    // Copy sharelink value to clipboard (you can use a library like clipboard.js)
    // For simplicity, I'm using the basic document.execCommand('copy')
    const textField = document.getElementById('shareLinkTextField');
    textField.select();
    document.execCommand('copy');
    setLinkCopied(true);
  };
  const closeShareModal = () => {
    setIsShareModal(false);
    handleClose();
  }
  const openDeleteModal = () => {
    setIsDeleteModal(true);

  }
  const closeDeleteModal = () => {
    setIsDeleteModal(false);
    handleClose();
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [msg, setMsg] = useState('')
 
  const handleClickShare = () => {
    setOpenSnackbar(true);
    setIsShareModal(false);
    setMsg('Shared Successfully')
  }

  const handleQueryChange = (event) => {
    // Update the editedQuery state when the TextField value changes
    setEditedQuery(event.target.value);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


  const handleEdit = async () => {
    
    try {
      seteditloading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await editQueryTitle(
        deepuser_id,
        deeporg_id,
        items.conversation_id,
        editedQuery,
        userToken
      );

      if (responseData.code === "200") {
       
        setresponsemsg(responseData.message)
        handleClose();
        setOpenSnackbar(true);
    
        setIsEditModal(false);
        fetchData();
        seteditloading(false)
      } else {
        seteditloading(false)
        setresponsemsg(responseData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      seteditloading(false)
    }
  };
  const handleDelete = async () => {
    
    try {
      setdeleteLoading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await deleteQueryTitle(
        deepuser_id,
        deeporg_id,
        items.conversation_id,
        userToken
      );

      if (responseData.code === "200") {
        handleClose();
        setOpenSnackbar(true);
        setresponsemsg(responseData.message)
        setIsDeleteModal(false);
        setdeleteLoading(false)
      
       fetchData();
      } else {
        showDialog();
        setOpenSnackbar(true);
        setresponsemsg(responseData.message)
        setdeleteLoading(false)
      }
    } catch (error) {
      console.error("Error:", error);
      setOpenSnackbar(true);
        setresponsemsg(responseData.message)
        setdeleteLoading(false)
      setdeleteLoading(false)
    }
  };
  const openShareModal = async () => {
    setsharelink("");
    setLoadingShareLink(true);
    const STATIC_URL =  `${window.constants.share_url}/query-share?`;;
    setIsShareModal(true);
    
    try {
    
        const userData = await localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        if (!userDataObj || !userDataObj.token) {
          console.error("User token not found in AsyncStorage.");
          return;
        }
        const userToken = userDataObj.token;
        const deepuser_id = userDataObj.user_id;
        const deeporg_id = userDataObj.org_id;
      const apiEndpoint = "shareconversation"; // Replace with your API endpoint
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
      const requestData = {
        user_id: deepuser_id,
        org_id: deeporg_id,
        conversation_id: items.conversation_id,
      };

      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();
      // console.log(responseData);
      if (responseData.code === "200") {
        handleClose();
        // console.log(responseData);
        const referenceNo = responseData.data[0].referenceno;
        // const conversation_id = responseData.data[0].referenceno;
        const dynamicPart = `${items.conversation_id}=${referenceNo}`;
       // setLoading(false);
        //  console.log(dynamicPart);
        // Concatenate the static URL with the dynamic part
        const finalURL = `${STATIC_URL}${dynamicPart}`;
        setLoadingShareLink(false);
        // Set the final URL to your state variable
        setsharelink(finalURL);
      } else {
        setLoadingShareLink(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <Box>
          <MenuItem onClick={openEditModal}>
            <span style={{ marginRight: '5px', marginTop: '4px' }}><EditIcon style={{ height: '17px', width: '17px' }} /></span> Edit
          </MenuItem>
         
          <MenuItem onClick={openShareModal}>
            <span style={{ marginRight: '5px', marginTop: '4px' }}><ShareIcon style={{ height: '17px', width: '17px' }} /></span> Share
          </MenuItem>
          
          <MenuItem onClick={openDeleteModal}>
            <span style={{ marginRight: '5px', marginTop: '4px' }}><DeleteIcon style={{ height: '17px', width: '17px' }} /></span> Delete
          </MenuItem>
      
        </Box>

        
        

      </Menu>
      <Dialog open={isEditModal} onClose={closeEditModal} >
            <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Edit your query title</DialogTitle>
            <DialogContent >
              <TextField label="Title" value={editedQuery}
            onChange={handleQueryChange} sx={{ marginTop: '20px', width: '500px' }}className='mobileview_modal' multiline rows={4} />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeEditModal} color="primary">
                Cancel
              </Button>
              <Button color="primary"onClick={handleEdit} disabled={editloading}>
          {editloading ? <CircularProgress size={24} /> : 'Apply'}
            
               
              </Button>
            </DialogActions>
          </Dialog>
      <Dialog open={isShareModal} onClose={closeShareModal} >
            <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Share Link</DialogTitle>
            <DialogContent >
              <Box>
                <p>Click this to copy a particular link that you wish to store/share with others</p>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loadingShareLink ? (
              <CircularProgress />
            ) : (
              <>
                <TextField label="Link" id="shareLinkTextField" value={sharelink}
                            onChangeText={(text) => setsharelink(text)} sx={{ width: '100%' }}  InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {linkCopied ? (
                                    <IconButton color="success">
                                      <CheckCircleIcon />
                                    </IconButton>
                                  ) : (
                                    <IconButton onClick={handleCopyToClipboard} color="primary">
                                      <ContentCopyIcon />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }} />
                                       </>
                                       
            )}
             </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeShareModal} color="primary">
                close
              </Button>
            
            </DialogActions>
          </Dialog>
      <Dialog open={isDeleteModal} onClose={closeDeleteModal} >
            <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Delete Confirmation</DialogTitle>
            <DialogContent sx={{ width: '500px' }} className='mobileview_modal'>
              Are you sure you want to delete this item?
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteModal} color="primary">
                Cancel
              </Button>
              <Button color="primary" onClick={handleDelete} disabled={deleteloading}>
          {deleteloading ? <CircularProgress size={24} /> : 'Apply'}
            
              </Button>
            </DialogActions>
          </Dialog>
      <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => handleCloseSnackbar(false)}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'info',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {responsemsg}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={() => handleCloseSnackbar(false)}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
    </div>
  );
}
export default BasicMenu;