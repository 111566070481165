import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TextField, List, Autocomplete, ListItem, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TodayIcon from '@mui/icons-material/Today';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function HistoryDateFilter(props, ref) {
  const { onApply, onApplycustomdate } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsShowMore(false);
  };

  const [isShowMore, setIsShowMore] = useState(false);
  const [buttonText, setButtonText] = useState('Date');
  const [selectedValue, setSelectedValue] = useState('');

 

  const showMore = () => {
    setIsShowMore(!isShowMore);
    // Reset the button text
  };

  const handleCustomDateApply = () => {
    // Apply custom date range logic here
    setButtonText('Custom Date'); // Change the button text to 'Custom Date' when applied
    setIsShowMore(false);
    setAnchorEl(null);
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
     onApplycustomdate({ fromDateval: formattedFromDate, toDateval: formattedToDate})
    console.log('Formatted From Date:', formattedFromDate);
    console.log('Formatted To Date:', formattedToDate);
 
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const modifyDateText = [
    { label: 'Today', value: 'today' },
    { label: 'Last 7 Days', value: '7days' },
    { label: 'Last 30 Days', value: '30days' },
    { label: 'This year', value: 'thisYear' },
    { label: 'Last Year', value: 'lastYear' },
  ];
  const handleListItemClick = (item, value) => {
    setButtonText(item);
    setSelectedValue(value);
    onApply(value);
    setAnchorEl(null);
  };
  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date not selected"; // or any other default message
    }
  
    const timeZone = "India Standard Time"; // Add your desired time zone here
  
    // Create a new Date object from the input string
    const date = new Date(dateString);
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
  
    // Set time to 00:00:00
    date.setHours(0, 0, 0, 0);
  
    // Format the date string manually in the required format
    const formattedDate = date.toDateString(); // Sat Nov 25 2023
    const formattedTime = date.toTimeString().substring(0, 8); // 00:00:00
    const gmtOffset = date.getTimezoneOffset(); // Offset in minutes
    const offsetHours = Math.floor(Math.abs(gmtOffset) / 60);
    const offsetMinutes = Math.abs(gmtOffset) % 60;
    const offsetSign = gmtOffset >= 0 ? '+' : '-';
  
    const formattedTimeZone = `GMT${offsetSign}${offsetHours}${offsetMinutes > 0 ? `:${offsetMinutes}` : ''}`; // GMT+0530
  
    return `${formattedDate} ${formattedTime} ${formattedTimeZone} (${timeZone})`;
  };
  
  // Example usage
  const formattedResult = formatDate("Tue, 31 Oct 2023 18:30:00 GMT");
  console.log(formattedResult); // Output: Sat Nov 25 2023 00:00:00 GMT+0530 (India Standard Time)
  const handleResetClick = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedValue('');
    setButtonText('Date');
    
    
  };

  useEffect(() => {
    // Use the ref to expose the handleResetClick function
    if (ref) {
      ref.current = {
        handleResetClick,
      };
    }
  }, [ref]);
  
  return (
    <div>
      <Button
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: '#2f80ed',
          },
          backgroundColor: '#2f80ed',
          color: 'white',
          textTransform: 'none',
          
        }}
      >
        {buttonText}{' '}
        {buttonText === 'Date' ? (
          <CalendarMonthIcon sx={{ height: '17px', width: '17px', marginLeft: '10px', marginTop: '-3px' }} />
        ) : (
          <CheckIcon sx={{ height: '17px', width: '17px', marginLeft: '10px', marginTop: '-3px' }} />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography>
          <Card
            variant="outlined"
            style={{
              display: 'flex',
            }}
          >
            <div>
              <div style={{ display: 'flex' }}>
                <CardContent style={{ display: 'flex' }}>
                  <List>
                    {modifyDateText.map((item) => (
                      <ListItem sx={{ margin: '0px', padding: '0px' }}>
                        <Button
                          sx={{ width: '100%', justifyContent: 'left' }}
                          onClick={() => handleListItemClick(item.label,item.value)}
                        >
                          {item.label}
                        </Button>
                      </ListItem>
                    ))}
                    <ListItem sx={{ margin: '0px', padding: '0px' }}>
                      <Button sx={{ width: 'max-content' }} onClick={showMore}>
                        Custom date range
                      </Button>
                    </ListItem>
                  </List>
                  <Divider />
                </CardContent>
                <Divider orientation="vertical" flexItem />
                {isShowMore && (
                  <div>
                    <Divider orientation="vertical" flexItem />
                    <CardContent style={{ flex: 1 }}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker label="From"   value={fromDate}
      onChange={(date) => setFromDate(date)}/>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="To"  value={toDate}
      onChange={(date) => setToDate(date)}/>
                        </DemoContainer>
                      </LocalizationProvider>
                    </CardContent>
                  </div>
                )}
              </div>
              {isShowMore && (
                <div>
                  <Divider orientation="horizontal" sx={{ marginBottom: '20px' }} flexItem />
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant="outlined"
                      sx={{ margin: '10px' }}
                      onClick={() => {
                        setButtonText('Date');
                        setAnchorEl(null);
                        setIsShowMore(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" sx={{ margin: '10px' }} onClick={handleCustomDateApply}>
                      Apply
                    </Button>
                  </Box>
                </div>
              )}
            </div>
          </Card>
        </Typography>
      </Popover>
    </div>
  );
}

export default React.forwardRef(HistoryDateFilter);