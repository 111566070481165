import React, { useState, useEffect,useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DrawerComponent from "../Includes/Sidebar";
import { Button, TextField } from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfiniteScroll from "react-infinite-scroll-component";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import NotesIcon from '@mui/icons-material/Notes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getAllDepartment, deleteDepartment, addDepartment, fetchMoreDepartment } from "../AdminApiHelper";
import Skeleton from "@mui/material/Skeleton";
import { CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
// import AddDepartment from "./addDepartment";
// import ViewDepartment from "./viewDepartment";
import Appbar from "../Includes/Header";

import "./Department.css";
import { borderRadius } from "@mui/system";
const drawerWidth = 130;
const theme = createTheme();
function Department(props) {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <DrawerComponent />
        </div>
    );

    const container =
        windows !== undefined ? () => window().document.body : undefined;

    const [departmentbutton, setDepartmentbutton] = React.useState(false);
    const handlebutton = () => {
        setDepartmentbutton(!departmentbutton);
    };
    const tableTitle = ['Sl. No.', 'Organisation', 'Department', 'Description', 'Action'];
    const tableData = [
        'a1.pdf', 'a2.pdf', 'a3.pdf', 'a4.pdf', 'a5.pdf', 'a6.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf', 'a7.pdf'
    ];
    const deptBtnStyle = {
        borderRadius: '200px',
        backgroundColor: 'var(--Secondary-blue, #003776)',
        textTransform: 'none'
    }
    const [isLoading, setIsLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [deptSearchResults, setDeptSearchResults] = useState([]);
    const [showStaticContent, setShowStaticContent] = useState(false);
    const [userconversation_id, setuserconversation_id] = useState("");
    const [dept_id, setDept_id] = useState([]);
    const [dept_name, setDept_name] = useState('');
    const [dept_desc, setDept_desc] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastmessage, settoastmessage] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalrec, setTotalrec] = useState('');
    useEffect(() => {
       

        fetchData();

        return () => { };
    }, []);
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(0);
            const data = await getAllDepartment(deepuser_id, deeporg_id, userToken);
            if (data.code == "200") {
                setuserconversation_id(data.conversation_id);
                const newResults = data.data;
                setDeptSearchResults(data.data);
                setTotalrec(data.total_rec)
                if (data.data.length >= 0 && data.data.length < 50) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
                setIsLoading(false)
                setDept_id(data.department_id)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const handleDelete = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const responseData = await deleteDepartment(
                deepuser_id,
                deeporg_id,
                dept_id,
                userToken
            );

            if (responseData.code === "200") {
                setIsDeleteModal(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                setButtonLoading(false)
                fetchData()
            } else {
                setSnackbarOpen(true);
                settoastmessage(responseData.message);
                setButtonLoading(false)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };

    const handleAddDepartment = async () => {
        setButtonLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const responseData = await addDepartment(
                deepuser_id,
                deeporg_id,
                dept_name,
                dept_desc,
                userToken
            );

            if (responseData.code === "200") {

                setButtonLoading(false)
                setDept_name('')
                setDept_desc('')
                setAddDeptModalOpen(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
                fetchData()
            } else {

                setButtonLoading(false);
                setSnackbarOpen(true);
                settoastmessage(responseData.message)
            }
        } catch (error) {
            console.error("Error:", error);
            //   setdeleteLoading(false)
        }
    };
    const handleLoadMore = async () => {
        setIsLoadingMore(true);

        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const {
                token: userToken,
                user_id: deepuser_id,
                org_id: deeporg_id,
            } = userDataObj;

            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
            setIndex(index + 50);
            const data = await fetchMoreDepartment(
                deepuser_id,
                deeporg_id,
                userToken,
                index + 50
            );
            if (data.code == "200") {
                setuserconversation_id(data.conversation_id);
                const newResults = data.data;
                setDeptSearchResults((prevResults) => [
                   
                    ...prevResults, ...newResults,
                ]);
                if ( data.data.length >= 0 && data.data.length <50) {
                    setHasMore(false)
                }
                else {
                    setHasMore(true);

                }
                setIsLoading(false)
                setShowStaticContent(false);
            } else {
                setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            setIsLoading(false);
        }
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const fetchDeptName = (event) => {
        setDept_name(event.target.value)
    }
    const fetchDeptDesc = (event) => {
        setDept_desc(event.target.value)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addDeptModalOpen, setAddDeptModalOpen] = useState(false)
    const openAddDeptModal = () => {
        setAddDeptModalOpen(true)
    };
    const closeAddDeptModal = () => {
        setAddDeptModalOpen(false)
    };
    const modalStyle = {
        borderRadius: '200px'
    }
    const [isDeleteModal, setIsDeleteModal] = useState(false)

    const openDeleteModal = (index, department_id) => {
        setIsDeleteModal(true);
        setDept_id(department_id);

    }
    const closeDeleteModal = () => {
        setIsDeleteModal(false);

    }
    const handleClickDelete = () => {

        setIsDeleteModal(false);

    }
    const LoadingSkeleton = () => {
        // You can customize the skeleton based on your design
        return (
            <TableRow>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>
                <TableCell>
                    <Skeleton width={150} height={20} />
                </TableCell>

            </TableRow>
        );
    };


      const tableContainerRef = useRef(null);
      useEffect(() => {
        const tableContainer = tableContainerRef.current;
      
        // Check if tableContainerRef.current is not null before adding event listener
        if (tableContainer) {
          const handleScroll = () => {
            // Check if the user has scrolled to the bottom of the table container
            if (
              tableContainer.scrollTop + tableContainer.clientHeight >=
              tableContainer.scrollHeight
            ) {
              // Trigger the load more function when scrolled to the bottom
              handleLoadMore();
            }
          };
      
          // Add scroll event listener to the table container
          tableContainer.addEventListener('scroll', handleScroll);
      
          return () => {
            // Remove scroll event listener on component unmount
            tableContainer.removeEventListener('scroll', handleScroll);
          };
        }
      }, [handleLoadMore, tableContainerRef]);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
        }}
      >
        <Appbar />
      </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                   
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    background: "#EFF1F3",
                    minHeight: "100vh",
                }}
            >
              

                <div className="department_main">
                    <Box sx={{ display: 'flex', justifyContent:'space-between', height:'22vh',paddingTop:'11vh'}}>
                    <Box sx={{
                        fontSize: '13px', textAlign: 'left',  color: 'gray',marginTop:'50px',marginLeft:'5px'
                    }}>
                        Total {totalrec ? totalrec : '0'} documents
                    </Box>
                        
                        <Box sx={{margin:'15px'}}>
                            <Button variant="contained" style={deptBtnStyle} onClick={openAddDeptModal}>+ Add Repository</Button>
                            <ThemeProvider theme={theme}>
                                <Dialog open={addDeptModalOpen}
                                    onClose={closeAddDeptModal}
                                    sx={{
                                        "& .MuiDialog-paper": {
                                            borderRadius: "20px",
                                        },
                                    }}
                                    className={{ paper: 'custom-dialog-paper' }}
                                >
                                    <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                                        <Box sx={{ fontWeight: 'bold' }}>
                                            Add Repository
                                        </Box>
                                        <Box position='absolute' left='90%'>
                                            <IconButton>
                                                <CloseIcon onClick={closeAddDeptModal} />
                                            </IconButton>
                                        </Box>
                                    </DialogTitle>
                                    <DialogContent sx={{ width: '500px' }}>
                                        <Box sx={{ marginTop: '20px' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>
                                            Repository Details
                                            </Box>
                                            <Box>
                                                <Box display='flex' sx={{ marginTop: '20px' }}>
                                                    <Box sx={{ fontWeight: 'bold' }}>
                                                    Repository Name
                                                    </Box>
                                                    <Box sx={{ borderRadius: '10px' }}>
                                                        <TextField sx={{ left: '19%', width: '270px', "& fieldset": { borderRadius: '10px' }, }}
                                                            placeholder="Enter Repository name"
                                                            onChange={fetchDeptName}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <IconButton  >
                                                                        <WorkOutlineIcon />
                                                                    </IconButton>
                                                                ),
                                                            }} />
                                                    </Box>

                                                </Box>
                                                <Box display='flex' sx={{ marginTop: '20px' }} >
                                                    <Box sx={{ fontWeight: 'bold' }}>
                                                        Description
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            multiline
                                                            maxRows={3}
                                                            sx={{ left: '38%', width: '270px', "& fieldset": { borderRadius: '10px' } }}
                                                            placeholder="Enter Description"
                                                            onChange={fetchDeptDesc}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <IconButton  >
                                                                        <NotesIcon />
                                                                    </IconButton>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>

                                                </Box>
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions sx={{ marginBottom: '10px', marginRight: '10px' }}>
                                        <Button onClick={closeAddDeptModal} variant="outlined" sx={{ borderRadius: '200px' }}>
                                            Cancel
                                        </Button>
                                        {/* <Button variant="contained" sx={{ borderRadius: '200px' }} onClick={handleAddDepartment}>
                                            Save Department
                                        </Button> */}
                                        <Button variant="contained" sx={{ borderRadius: '200px' }} onClick={handleAddDepartment} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                                            {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}}/>} Save Repository
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </ThemeProvider>
                        </Box>
                    </Box>
                    {/* <Box sx={{ textAlign: 'left', color: 'grey' }}>
                        View and manage all your departments here.
                    </Box> */}

                    <Box>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                           
                                <Box>
                                <TableContainer 
                                 ref={tableContainerRef}
                                sx={{ height: '77.9vh' , overflow: 'auto'}}
                                >
                                    <Table  size="small" stickyHeader aria-label="a dense table">
                                        <TableHead sx={{ backgroundColor: '#eff1f3' }}>
                                            <TableRow >

                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderLeft: '4px solid white' }}>
                                                    Sl.No.
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    Organisation
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    Repository
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white' }}>
                                                    Description
                                                </TableCell>
                                                <TableCell sx={{ backgroundColor: '#eff1f3', color: 'Black', borderTop: '4px solid white', borderRight: '4px solid white' }}>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {isLoading ? (
                                // Render loading skeletons while data is being fetched
                                Array.from({ length: 10 }).map((_, index) => (
                                    <LoadingSkeleton key={index} />
                                ))
                            ) : (
                                        <TableBody>
                                            {deptSearchResults.map((item, index) => (
                                                <TableRow sx={{ padding: '0px', height: '20px' }}>
                                                    <TableCell sx={{ padding: '0px 0px 0px 20px' }}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {item.organization_name}
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        {item.department_name}
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>
                                                        <div style={{ display: 'flex', padding: '0px', margin: '0px' }}>
                                                            {item.description}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell sx={{ color: 'grey' }}>

                                                        <Button variant="outlined" style={{ borderColor: '#FF0000', color: '#FF0000', borderRadius: '10px', fontSize: '10px' }} color="error" onClick={() => openDeleteModal(index, item.department_id)}>Delete</Button>


                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                          
                                        </TableBody>
                                        )}
                                    </Table>
                                  
         
                                    <InfiniteScroll
                            dataLength={deptSearchResults.length}
                            next={handleLoadMore}
                            hasMore={hasMore}
                            loader={
                                <CircularProgress disableShrink style={{ margin: "20px" ,justifyContent:'center'}} />
                            }
                            endMessage={
                                <div style={{ textAlign: "center", marginTop: "10px" }}>
                                    {deptSearchResults.length == 0 ? (
                                        <div
                                            style={{
                                                color: "grey",
                                                fontSize: "13px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            No record found
                                        </div>
                                    ) : (
                                        <>
                                            <div>No More Records</div>
                                           
                                        </>
                                    )}
                                </div>
                            }
                        ></InfiniteScroll>
       
         
                                </TableContainer>

{/* <TableContainer >
<Table  size="small" aria-label="a dense table">
  <TableHead>
    <TableRow>
      <TableCell>Dessert (100g serving)</TableCell>
      <TableCell align="right">Calories</TableCell>
      <TableCell align="right">Fat&nbsp;(g)</TableCell>
      <TableCell align="right">Carbs&nbsp;(g)</TableCell>
      <TableCell align="right">Protein&nbsp;(g)</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {rows.map((row) => (
      <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>
</TableContainer> */}
</Box>

                            
                            <Dialog open={isDeleteModal} onClose={closeDeleteModal} >
                                <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Delete Confirmation</DialogTitle>
                                <DialogContent sx={{ width: '500px' }}>
                                    Are you sure you want to delete this item?
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closeDeleteModal} color="primary">
                                        Cancel
                                    </Button>
                                    {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
                                    <Button onClick={handleDelete} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                                        {buttonLoading && <CircularProgress size={16} sx={{marginRight:'10px'}}/>} Apply
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Paper>
                       
                    </Box>

                </div>
            </Box>
            <Snackbar
                mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </Box>
    );
}

export default Department;
