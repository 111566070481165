import { Typography, FormControl, InputLabel, Select, MenuItem, Paper, Avatar, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CircularProgress } from '@mui/material';
import uploadimg from '../../../file_upload.svg';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';
import image from '../../../avatar.png';
import { getAllDepartment, initiateDocumentUpload, getAllShareUser, completeDocumentUpload, userMasterData, accessUser } from "../AdminApiHelper";
import { AddBox } from "@mui/icons-material";
const Step1Modal = ({ onDepartmentChange, selectedDepartment }) => {

    // Sample accessUser array with user objects
    // const accessUser = [
    //     { userId: 1, name: 'John' },
    //     // { name: 'Jane' }, // Example without userId property
    //     // { userId: 3, name: 'Bob' },
    //   ];

    //   // Creating a new array to store only the userIds for objects with userId property
    //   const userIdsArray = accessUser
    //     .filter(user => user.userId !== undefined)
    //     .map(user => user.userId);

    //   // Displaying the result
    //   if (userIdsArray.length === 1) {
    //     console.log("Single value:", userIdsArray[0]);
    //   } else {
    //     console.log("Array value:", userIdsArray);
    //   }

    const [usertoken, setusertoken] = useState("");
    const [index, setIndex] = useState(0);
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const handleChange = (event) => {
        setDepartment(event.target.value);
        onDepartmentChange(event.target.value);
    };
    const [department, setDepartment] = useState('')
    const [deptSearchResults, setDeptSearchResults] = useState([]);
    const [userconversation_id, setuserconversation_id] = useState("");
    useEffect(() => {
        const fetchMasterData = async () => {
            // setIsLoading(true);
            try {
                const storedUserData = localStorage.getItem("userdata");

                if (!storedUserData) {
                    console.error("User data not found in localStorage.");
                    return;
                }

                const userDataObj = JSON.parse(storedUserData);
                console.log(userDataObj);
                if (!userDataObj || !userDataObj.token) {
                    console.error(
                        "User token not found in localStorage or component unmounted."
                    );
                    return;
                }

                const {
                    token: userToken,
                    user_id: deepuser_id,
                    org_id: deeporg_id,
                } = userDataObj;

                setusertoken(userToken);
                setuser_id(deepuser_id);
                setuserorg_id(deeporg_id);
                setIndex(0);
                const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
                if (requestData.code == "200") {
                    setuserconversation_id(requestData.conversation_id);
                    setDeptSearchResults(requestData.department);
                    // setIsLoading(false)
                    // setShowStaticContent(false);
                } else {
                    // setShowStaticContent(true);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                // Make sure to set isLoading to false whether the fetch was successful or not
                // setIsLoading(false);
            }
        };

        fetchMasterData();

        return () => { };
    }, []);
    return (
        <>
            <Box>
                <Typography sx={{ fontWeight: 'bold', marginBottom: '15px' }}>Select Repository </Typography>
                <Typography sx={{ marginBottom: '15px' }}>Select a repository and proceed next to initiate document uploading</Typography>
                <Box>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Choose Repository</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedDepartment}
                            label="Choose Destination"
                            onChange={handleChange}
                        >
                            {deptSearchResults.map((item, index) => (

                                <MenuItem value={item.dept_id}>{item.dept_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </>
    )
}
const Step2Modal = ({ selectedDepartment, onUploadFiles, uploadFiles, compUpload }) => {
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    // console.log(' selectedDepartment', selectedDepartment)
    // const [multiFiles, setMultiFiles] = useState([])
    const handleFileChange = (event) => {
        const files = event.target.files;
        const maxFiles = 10; // Maximum number of files allowed

        if (files.length > maxFiles) {
            event.preventDefault();
            alert(`You can select a maximum of ${maxFiles} files.`);
            return;
        }

        setSelectedFiles([...selectedFiles, ...files]);

        // Disable file input if the maximum number of files is reached
        if (selectedFiles.length + files.length >= maxFiles) {
            fileInputRef.current.disabled = true;
        }

        const selectedFilesArray = Array.from(files);
        const fileNames = selectedFilesArray.map((file) => file.name);
        handleInitiateUpload(fileNames, files);
    };




    // const handleFileChange = (event) => {
    //     const files = event.target.files;
    //     setSelectedFiles([...selectedFiles, ...files]);
    //     const selectedFilesArray = Array.from(files);
    //     const fileNames = selectedFilesArray.map((file) => file.name);
    //     handleInitiateUpload(fileNames, files)
    // };
    // console.log('selectedFiles',selectedFiles)
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleCloseClick = (index) => {
        setSelectedFiles((prevSelectedFiles) => {
            const updatedFiles = [...prevSelectedFiles];
            updatedFiles.splice(index, 1); // Remove the file at the specified index
            return updatedFiles;
        });
    };
    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };
    const [progressComplete, setProgressComplete] = useState(false)
    const [initiateData, setInitiateData] = useState([])
    const [fileMsg, setFileMsg] = useState([])
    const [fileStates, setFileStates] = useState({});
    // console.log("initiateData",initiateData.map((file) => file.presignedUrl))
    console.log('fileMsg', fileMsg)
    const [fileStatus, setFileStatus] = useState([]);
    const [successfulFiles, setSuccessfulFiles] = useState([]);
    // Function to update the status of a specific file
    const updateFileStatus = (index, status) => {
        setFileStatus(prevFileStatus => {
            const newFileStatus = [...prevFileStatus];
            newFileStatus[index] = status;
            return newFileStatus;
        });
    };
    const handleInitiateUpload = async (fileNames, files) => {
        setSpinnerLoading(true)
        try {
            //   setdeleteLoading(true)
            const userData = await localStorage.getItem("userdata");
            const userDataObj = JSON.parse(userData);
            if (!userDataObj || !userDataObj.token) {
                console.error("User token not found in AsyncStorage.");
                return;
            }
            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;


            const responseData = await initiateDocumentUpload(
                deepuser_id,
                deeporg_id,
                fileNames,
                selectedDepartment,
                userToken
            );

            if (responseData.code === '200') {
                setSpinnerLoading(false)
                setUploadProgress({});
                const totalFilesCount = files.length; // Dynamic total number of files being uploaded
                let successfulFilesCount = 0; // Initialize count of successful files
                compUpload(false)

                // const updatedFileMsgs = {};
                responseData.data.forEach((file, index) => {
                    if (file.presignedUrl == '') {
                        // Set the file message for a particular file
                        let fileMsgs = file.message;
                        setFileMsg(fileMsgs);
                        successfulFilesCount++;
                    }

                    if (file.presignedUrl !== ' ') {
                        const xhr = new XMLHttpRequest();
                        const doc = files[index];

                        xhr.open('PUT', file.presignedUrl, true);
                        xhr.setRequestHeader('Content-Type', doc.type);

                        xhr.upload.addEventListener('progress', (event) => {
                            if (event.lengthComputable) {
                                const progress = (event.loaded / event.total) * 100;
                                setUploadProgress((prevProgress) => ({
                                    ...prevProgress,
                                    [file.filename]: progress,
                                }));
                            }
                        });

                        xhr.onreadystatechange = () => {
                            if (xhr.readyState === XMLHttpRequest.DONE) {
                                if (xhr.status === 200 || xhr.status === 204) {
                                    setProgressComplete(true)
                                    successfulFilesCount++;

                                    console.log(`File ${file.filename} - Upload Completed`);
                                    if (successfulFilesCount == totalFilesCount) {
                                        compUpload(true)

                                    }
                                    setSuccessfulFiles(prevSuccessfulFiles => [...prevSuccessfulFiles, files[index]]);
                                    updateFileStatus(index, 'success');
                                } else {
                                    updateFileStatus(index, 'error');
                                    console.error(`File ${file.filename} - Upload Error: ${xhr.status}`);
                                }
                            }
                        };

                        xhr.send(doc);
                    }

                });
            } else {
                // setSnackbarOpen(true);
                // settoastmessage(responseData.message);
                setSpinnerLoading(false)
            }
        } catch (error) {
            console.error("Error:", error);
            setSpinnerLoading(false)
            //   setdeleteLoading(false)
        }
    };
    useEffect(() => {
        onUploadFiles(successfulFiles);
    }, [successfulFiles, onUploadFiles]);
    const [uploadProgress, setUploadProgress] = useState({});
    const renderContent = (file, index) => {
        // Perform conditional logic here
        const status = fileStatus[index];
        if (status === 'success') {
            return <CheckIcon style={{ color: 'blue' }} />;
        } else if (status === 'error') {
            return (
                <div style={{ fontSize: '11px' }}>
                    {fileMsg}
                </div>
            );
        } else if ((spinnerLoading) || (status != 'sucess' && uploadProgress[file.name] == 100)) {
            return <CircularProgress size={30} />;
        } else if (uploadProgress[file.name] !== undefined && uploadProgress[file.name] < 100) {
            return (
                <div>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" size={30} value={uploadProgress[file.name]} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: '11px' }}>
                                {`${Math.round(uploadProgress[file.name])}%`}
                            </Typography>
                        </Box>
                    </Box>
                </div>
            );
        } else {
            return null; // Return null if none of the conditions are met
        }
    };
    return (
        <>
            <div>
                {/* <input
        type="file"
        onChange={handleFileChange}
        multiple
        
        
        name="Click to upload"
      /> */}
                {/* <div style={{ margin: '10px 0px' }}> <strong>Select Files </strong></div> */}
                {/* <div style={{ color: '#606060' }}>Drop your files here to upload.</div> */}
                {/* <Box sx={{ display: 'flex' }}>
                    <Box><img src={uploadimg} alt="" style={{ height: '20px', width: '20px' }} /></Box>
                    <Typography onClick={handleButtonClick} sx={{ textDecoration: 'none',cursor:'pointer' }}>Click here to upload file </Typography>
                </Box>

                <input
                    type="file"
                    accept=".doc, .docx, .pdf"
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple="multiple"
                    // value={uploadFiles.name}
                /> */}
                <Box sx={{
                     backgroundColor: 'white',
                     cursor: 'pointer',
                     // width: '500px',
                     position: 'sticky',
                     top: '0',
                     overflow: 'hidden',
                }}>
                <div
                    // onDragOver={handleDragOver}
                    // onDrop={handleDrop}
                    style={{
                        border: '2px dashed #ccc',
                        borderRadius: '10px',
                        padding: '20px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        textAlign: 'center',
                        backgroundColor: 'white',
                        cursor: 'pointer',
                        // width: '500px',
                        position: 'sticky',
                        top: '0',
                        overflow: 'hidden',
                        display: 'block',
                        justifyContent: 'center'
                    }}
                >
                    <div>
                        <div style={{ color: '#003776', display: 'flex', textAlign: "center", justifyContent: 'center' }}>
                            <img src={uploadimg} alt="" style={{ height: '20px', width: '20px' }} onClick={handleButtonClick} />
                             <Typography onClick={handleButtonClick} sx={{ textDecoration: 'underline' }}>Click to upload </Typography>
                            <Typography sx={{ marginLeft: '5px' }} onClick={handleButtonClick}> {" or drag and drop files here"} </Typography>
                        </div>
                        <input
                            type="file"
                            accept=".doc, .docx, .pdf"
                            hidden
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            multiple="multiple"
                        />
                        <div style={{ color: '#44474F', fontSize: '13px' }}>Supported file formats: pdf & docx.{'(maximum 10 files)'}
                        </div>
                       
                    </div>


                </div>

               
                </Box>

                {selectedFiles.length > 0 && (
                    <Box>
                        {selectedFiles.map((file, index) => (
                            // <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '20px 0px' }}>

                            //                             <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>

                            //                                 <Box sx={{ marginTop: '0px', display: 'flex' }}>
                            //                                     <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={file.name}>{file.name}</Box>
                            //                                     <Box sx={{ fontSize: '12px', marginTop: "2px" }}> 
                            //                                     {file.size < 1024 && 
                            //                                                         <Typography sx={{fontSize:'13px'}}>{file.size}byte</Typography>
                            //                                                         }
                            //                                                         {file.size >= 1024 && file.size < (1024*1024) && 
                            //                                                         <Typography sx={{fontSize:'13px'}}>{(file.size / 1024 ).toFixed(1)}kb</Typography>
                            //                                                         }
                            //                                                         {file.size >= (1024*1024) && 
                            //                                                         <Typography sx={{fontSize:'13px'}}>{(file.size / (1024 * 1024)).toFixed(1)}mb</Typography>
                            //                                                         } 
                            //                                     </Box>
                            //                                 </Box>


                            //                                 <Box>
                            //                                     {/* {selectedFiles.map((file, index) => ( */}
                            //                                     <div key={index}>
                            //                                         {/* Display CheckIcon if progressComplete is true */}
                            //                                         {/* {progressComplete && (
                            //                                             <div>
                            //                                                 <CheckIcon style={{ color: 'blue' }} />
                            //                                             </div>
                            //                                         )} */}
                            //                                         {/* Display fileMsg if it's not 'Success' */}
                            //                                         {/* {fileMsg !== 'Success'  && (
                            //                                             <div style={{ fontSize: '11px' }}>
                            //                                                 {fileMsg}
                            //                                             </div>
                            //                                         )} */}

                            //                                         {/* {fileMsg.undefined && progressComplete==false && (
                            //     <div style={{ fontSize: '11px' }}>
                            //         {fileMsg.undefined}
                            //     </div>
                            // )} */}
                            //                                         {/* {spinnerLoading && <CircularProgress size={30} />} */}
                            //                                         {/* Display CircularProgress if uploadProgress is defined and less than 100 */}
                            //                                         {/* {uploadProgress[file.name] !== undefined && uploadProgress[file.name] < 100 && (
                            //                                             <div>
                            //                                                 <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            //                                                     <CircularProgress variant="determinate" size={30} value={uploadProgress[file.name]} />
                            //                                                     <Box
                            //                                                         sx={{
                            //                                                             top: 0,
                            //                                                             left: 0,
                            //                                                             bottom: 0,
                            //                                                             right: 0,
                            //                                                             position: 'absolute',
                            //                                                             display: 'flex',
                            //                                                             alignItems: 'center',
                            //                                                             justifyContent: 'center',
                            //                                                         }}
                            //                                                     >
                            //                                                         <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: '11px' }}>
                            //                                                             {`${Math.round(uploadProgress[file.name])}%`}
                            //                                                         </Typography>
                            //                                                     </Box>
                            //                                                 </Box>
                            //                                             </div>
                            //                                         )} */}

                            // {renderContent(file,index)}
                            //                                     </div>
                            //                                     {/* ))} */}
                            //                                 </Box>

                            //                                 {/* <Box sx={{marginBottom:'-5px'}} onClick={() => handleCloseClick(index)}><CloseIcon /></Box>
                            //                             <Box sx={{fontSize:'13px'}}>---%</Box>
                            //                         </Box> */}
                            //                                 {/* </Box> */}

                            //                                 {/* <Box marginBottom='15px'sx={{padding:'0px 10px'}}><LinearProgress variant="determinate" value='100' /></Box> */}
                            //                             </Box>
                            <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '20px 0px', maxHeight: '60px' }}>
                                <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
                                    <Box display='flex'>
                                        <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '35px', width: '35px' }} /></Box>
                                        <Box sx={{ marginTop: '10px' }}>
                                            <Box sx={{ fontSize: '14px', fontWeight: 'bold', maxWidth: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={file.name}>{file.name}</Box>
                                            <Box sx={{ fontSize: '12px' }}>
                                                {file.size < 1024 &&
                                                    <Typography sx={{ fontSize: '13px' }}>{file.size}Byte</Typography>
                                                }
                                                {file.size >= 1024 && file.size < (1024 * 1024) &&
                                                    <Typography sx={{ fontSize: '13px' }}>{(file.size / 1024).toFixed(1)}{' '} KB</Typography>
                                                }
                                                {file.size >= (1024 * 1024) &&
                                                    <Typography sx={{ fontSize: '13px' }}>{(file.size / (1024 * 1024)).toFixed(1)}{' '} MB</Typography>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ margin: '18px 10px 10px 10px' }}>
                                        {renderContent(file, index)}
                                    </Box>
                                </Box>

                            </Box>
                        ))}</Box>
                )}
                {/* <div>
                    <Box sx={{backgroundColor:'#f5f3f7',border:'1px solid #74777f',borderRadius:'10px',margin:'20px 0px'}}>
                        <Box display='flex' flexGrow={1} sx={{justifyContent:'space-between'}}>
                            <Box display='flex'>
                                <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px',height:'35px',width:'35px' }} /></Box>
                                <Box sx={{marginTop:'10px'}}>
                                    <Box sx={{fontSize:'14px',fontWeight:'bold'}}>{file.name}</Box>
                                    <Box sx={{fontSize:'12px'}}>
                                    {file.size < 1024 && 
                                                        <Typography sx={{fontSize:'13px'}}>{file.size}byte</Typography>
                                                        }
                                                        {file.size >= 1024 && file.size < (1024*1024) && 
                                                        <Typography sx={{fontSize:'13px'}}>{(file.size / 1024 ).toFixed(1)}kb</Typography>
                                                        }
                                                        {file.size >= (1024*1024) && 
                                                        <Typography sx={{fontSize:'13px'}}>{(file.size / (1024 * 1024)).toFixed(1)}mb</Typography>
                                                        } 
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{margin:'10px'}}>
                                {renderContent(file,index)}
                            </Box>
                        </Box>
                        
                    </Box>
                    <Box sx={{backgroundColor:'#f5f3f7',border:'1px solid #74777f',borderRadius:'10px',margin:'20px 0px'}}>
                        <Box display='flex' flexGrow={1} sx={{justifyContent:'space-between'}}>
                            <Box display='flex'>
                                <Box><PictureAsPdfIcon sx={{ color: "red", margin: '10px',height:'35px',width:'35px' }} /></Box>
                                <Box sx={{marginTop:'10px'}}>
                                    <Box sx={{fontSize:'14px',fontWeight:'bold'}}>Test.pdf</Box>
                                    <Box sx={{fontSize:'12px'}}>103 kb</Box>
                                </Box>
                            </Box>
                            <Box sx={{margin:'10px'}}>
                                <Box sx={{marginBottom:'-5px'}}><CloseIcon /></Box>
                                <Box sx={{fontSize:'13px'}}>44%</Box>
                            </Box>
                        </Box>
                        <Box marginBottom='15px'sx={{padding:'0px 10px'}}><LinearProgress variant="determinate" value='50' /></Box>
                    </Box>
                </div> */}
            </div>
        </>
    )
}
const Step3Modal = ({ selectedUsers, accessUser }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [inputValue, setInputValue] = useState('');
    console.log('accessUser', accessUser)
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        selectedUsers(newInputValue);
    };
    console.log('newValue', inputValue)
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            setSelectedItems([...selectedItems, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleDelete = (itemToDelete) => {
        setSelectedItems((prevItems) => prevItems.filter((item) => item !== itemToDelete));
    };
    const chipcolor = {
        color: 'red'
    }
    const [department, setDepartment] = useState('')
    const [userSearchResults, setUserSearchResults] = useState([]);
    const [userconversation_id, setuserconversation_id] = useState("");

    useEffect(() => {


        fetchUserData();

        return () => { };
    }, []);

    const fetchUserData = async () => {
        // setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const requestData = await getAllShareUser(deepuser_id, deeporg_id, userToken);
            if (requestData.code == "200") {
                setuserconversation_id(requestData.conversation_id);
                const newResults = requestData.data;
                setUserSearchResults((prevResults) => [
                    ...newResults,
                    ...prevResults,
                ]);
                // setHasMore(requestData.data.length > 0);
                // console.log("Type of userSearchResults:", Array.isArray(userSearchResults));
                // setIsLoading(false)
                // setShowStaticContent(false);
            } else {
                // setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };
    const getOptionLabel = (option) => `${option.name} `;

    const renderOption = (props, option) => (
      <li {...props} style={{ display: "block" }}>
        <Box>
          <Box display="flex" style={{ fontSize: "0.8em" }}>
            <Box>
              <img src={option.photo} style={{height:'35px',width:'35px',borderRadius:'50px',marginRight:'5px'}}/>
            </Box>
            <Box fontWeight="bold" sx={{ marginTop: "5px" }}>
              {option.name}
              <Box display="flex" style={{ fontSize: "0.8em", color: "gray" }}>
            <Box>
              {"Email: "}
              {option.email}{" "}
            </Box>
            <Box sx={{ margin: "0px 5px" }}> | </Box>
            <Box>
              {"Department: "}
              {option.department}
            </Box>
          </Box>
            </Box>
           
          </Box>
          
        </Box>
      </li>
    );


    return (
        <>
            <Box >
                <Typography fontWeight='bold' sx={{ marginTop: '16px', marginBottom: '6px' }}>Select User </Typography>
                <Typography>Select users you want to give access to the file</Typography>
                <Box>
                    {Array.isArray(userSearchResults) &&
                        <Autocomplete
                            sx={{ marginTop: '10px' }}
                            multiple
                            id="tags-outlined"
                            // options={userSearchResults.filter(option => !accessUser.includes(option))}
                            options={userSearchResults.filter(option => !accessUser.some(user => user.user_id === option.user_id))}
                            // options={userSearchResults}
                            getOptionLabel={getOptionLabel}
                            renderOption={renderOption}
                            value={accessUser}
                            onChange={(event, newValue) => handleInputChange(event, newValue)}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select User"
                                    placeholder="Add.."
                                />
                            )}


                        />}
                </Box>

            </Box>
        </>
    )
}
const Step4Modal = ({ selectedDepartment, accessUser, uploadFiles }) => {

    const userIdsArray = accessUser.map(user => user.user_id);
    const fileName = uploadFiles.map(file => file.name);
    const fileSize = uploadFiles.map(file => file.size);
    console.log('userIdsArray', userIdsArray)
    console.log('username', fileName[0])
    console.log('userIdsArray', fileSize[0])
    const confirmUpload = async () => {
        // setIsLoading(true);
        try {
            const storedUserData = localStorage.getItem("userdata");

            if (!storedUserData) {
                console.error("User data not found in localStorage.");
                return;
            }

            const userDataObj = JSON.parse(storedUserData);
            console.log(userDataObj);
            if (!userDataObj || !userDataObj.token) {
                console.error(
                    "User token not found in localStorage or component unmounted."
                );
                return;
            }

            const userToken = userDataObj.token;
            const deepuser_id = userDataObj.user_id;
            const deeporg_id = userDataObj.org_id;
            const requestData = await completeDocumentUpload(
                deepuser_id,
                deeporg_id,
                fileName[0],
                fileSize[0],
                selectedDepartment,
                userIdsArray,
                userToken);
            if (requestData.code == "200") {
                // setuserconversation_id(requestData.conversation_id);
                const newResults = requestData.data;
                // setUserSearchResults((prevResults) => [
                //     ...newResults,
                //     ...prevResults,
                // ]);
                // setHasMore(requestData.data.length > 0);

                // setIsLoading(false)
                // setShowStaticContent(false);
            } else {
                // setShowStaticContent(true);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            // Make sure to set isLoading to false whether the fetch was successful or not
            // setIsLoading(false);
        }
    };
    return (
        <>
            <Box>
                {accessUser.length > 0 &&
                    <Box>
                        <Box>
                            <Typography fontWeight='bold' variant="h6">Confirm document sharing</Typography>
                        </Box>

                        <Box>
                            <Typography variant="h6" sx={{ color: '#606060' }}>Review and confirm sharing document to the selected users.</Typography>
                        </Box>
                        <Box sx={{ marginTop: '15px' }}>
                            <Typography>The selected users</Typography>
                            <Typography>
                                {accessUser.map((item, index) => (
                                    <Chip
                                        title={item.name}
                                        key={index}
                                        label={item.name}
                                        sx={{ margin: 0.5, backgroundColor: '#D0E2FB' }}
                                        avatar={

                                            <img src={item.photo} alt="Avatar" style={{ width: '25px', height: '25px', borderRadius: '50px' }} />

                                        }
                                    />
                                ))}

                            </Typography>
                        </Box>
                    </Box>
                }
                {accessUser.length == 0 && <Typography>Click on confirm button to submit the below documents</Typography>}
                <Box sx={{ marginTop: '24px' }}>
                    {accessUser.length > 0 && <Typography>Will have access to</Typography>}
                    <div>
                        {uploadFiles.map((item, index) => (
                            <Box sx={{ backgroundColor: '#f5f3f7', border: '1px solid #74777f', borderRadius: '10px', margin: '20px 0px' }}>
                                <Box display='flex' flexGrow={1} sx={{ justifyContent: 'space-between' }}>
                                    <Box display='flex'>
                                        <Box sx={{ marginTop: '10px' }}><PictureAsPdfIcon sx={{ color: "red", margin: '10px', height: '20px', width: '20px' }} /></Box>
                                        <Box sx={{ marginTop: '20px' }}>
                                            <Box sx={{ fontSize: '14px', fontWeight: 'bold' }}>{item.name}</Box>

                                        </Box>
                                    </Box>
                                    {/* <Box sx={{ margin: '20px' }}>
                                        <Box sx={{ fontSize: '14px' }}>{item.size} kb</Box>
                                    </Box> */}
                                </Box>

                            </Box>
                        ))}

                    </div>
                </Box>
            </Box>
        </>
    )
}
export { Step1Modal, Step2Modal, Step3Modal, Step4Modal };