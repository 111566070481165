import React, { useEffect ,useState} from 'react';
import { Await, useLocation } from 'react-router-dom';
import { downloadPDF, saveannotation, updateannotation, deleteannotation, getallannotation } from '../ApiHelper/DocumentSearchapi';
import Snackbar from '@mui/material/Snackbar';
import {SnackbarContent,IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const PDFViewer = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pdfFileName = searchParams.get('docName');
  const pdfUrl = searchParams.get('url');
  const pagenumber = searchParams.get('pagenumber');
  const docId = searchParams.get('docId');
  const index = searchParams.get('index');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastmessage, settoastmessage] = useState('');
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setSnackbarOpen(false);
};
  useEffect(() => {
    const loadPDFViewer = async () => {
      try {

        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        if (!userDataObj || !userDataObj.token) {
          console.error("User token not found in AsyncStorage.");
          return;
        }
        const userToken = userDataObj.token;
        const deepuser_id = userDataObj.user_id;
        const deepuser_name = userDataObj.full_name;
        const deeporg_id = userDataObj.org_id;

        // Your existing PDF data retrieval logic goes here
        const responseData = await downloadPDF(deepuser_id, deeporg_id, docId, pdfFileName, userToken);

        if (responseData.code == "200") {
          const pdfUrl = responseData.data[0].doclink;
          console.log(pdfUrl);
          // Adobe DC View SDK is ready after script loading
          const viewerConfig = {
            /* Add any configuration options as needed */
            enableAnnotationAPIs: true, // Enable annotation APIs
            includePDFAnnotations: true, // Include PDF annotations
          };

          /*
          const profile = {
            userProfile: {
              "id": 12,
              "name": deepuser_name
            }
          };
          */
          const profile = {
            userProfile: {
                "id": ''+deepuser_id,
                "name": deepuser_name
            }
        };
          
          const adobeDCView = new AdobeDC.View({
            clientId: `${window.constants.embeded_api_clientId}`, // Replace with your Adobe client ID
            divId: 'adobe-pdf-viewer',
            userProfile: profile,
          });
          // Function to handle annotation events
          const handleAnnotationEvents = async adobeViewer => {
            adobeViewer.getAnnotationManager().then(annotationManager => {
              // Add your annotation-related logic here
              console.log('get all 1');
              annotationManager.getAnnotations().then(async result => {
                console.log('get all 2');
                try {
                  console.log('get all 3');
                  // Assuming getallannotation returns a Promise
                  const res = await getallannotation(deepuser_id, docId, userToken);
                  console.log('get all 4', res);
                  if (res.code == 200) {
                    console.log('get all 5', res.data);

                    // Process and add the annotations to the annotation manager
                    const annotationar = res.data.map(annotationData => {
                      let annotation = JSON.parse(annotationData.annotation);
                      annotation = JSON.parse(
                        JSON.stringify(annotation).replace(/\:null/gi, ':""')
                      );
                      return annotation;
                    });

                    // Add annotations to the annotation manager
                    annotationManager
                      .addAnnotations(annotationar)
                      .then(() => console.log("Annotations added successfully"))
                      .catch(error => console.log("Error adding annotations:", error));
                  } else {
                    // Handle the case when the request to get annotations fails
                    console.error("Error getting annotations:", res.message);
                  }
                } catch (error) {
                  console.error("Error fetching annotations:", error);
                }
              });



              annotationManager.registerEventListener(async function (event) {


                if (event.type == "ANNOTATION_ADDED") {
                  console.log('add..')
                  console.log(event.data)
                  let postdata = {
                    orgid: userDataObj.org_id,
                    user_id: userDataObj.user_id,
                    projectid: '0',
                    doc_id: docId,
                    annotation_refid: event.data.id,
                    annotation: event.data
                  }
                  let dataObj = postdata
                  // createAnnotation([dataObj, userToken]).then(jsonData => console.log("Annotation data:", jsonData));
                  const res = await saveannotation(deepuser_id, deeporg_id, '1', docId, event.data.id, event.data, userToken)
                  console.log('rescode', res)
                  if (res.code == 200) {
                    console.log('save--')
                  }

                }
                else if (event.type == "ANNOTATION_UPDATED") {
                  console.log(event.data)
                  console.log('update..')
                  const res = await updateannotation(deepuser_id, deeporg_id, '1', docId, event.data.id, event.data, userToken)
                  console.log('rescode', res)
                  if (res.code == 200) {
                    console.log('update--')
                  }
                  else if (res.code == '500') {
                    setSnackbarOpen(true);
                    settoastmessage(res.message)
                    const annotationar = res.data.map(annotationData => {
                      let annotation = JSON.parse(annotationData.annotation);
                      annotation = JSON.parse(
                        JSON.stringify(annotation).replace(/\:null/gi, ':""')
                      );
                      return annotation;
                    });
                    console.log('update+add',annotationar)
                    // Add annotations to the annotation manager
                    annotationManager
                      .updateAnnotation(annotationar[0])
                      .then(() => console.log("Annotations added successfully"))
                      .catch(error => console.log("Error adding annotations###:", error));
                   
                  }

                }
                else if (event.type == "ANNOTATION_DELETED") {
                  console.log(event.data)
                  console.log('delete..')
                  const res = await deleteannotation(deepuser_id, deeporg_id, '1', docId, event.data.id, userToken)
                  console.log('rescode', res)
                  if (res.code == 200) {
                    console.log('delete--')
                  }
                  else if (res.code == '500') {
                    setSnackbarOpen(true);
                    settoastmessage(res.message)
                    const annotationar = res.data.map(annotationData => {
                      let annotation = JSON.parse(annotationData.annotation);
                      annotation = JSON.parse(
                        JSON.stringify(annotation).replace(/\:null/gi, ':""')
                      );
                      return annotation;
                    });
                    console.log('dlt+add')
                    // Add annotations to the annotation manager
                    annotationManager
                      .addAnnotations(annotationar)
                      .then(() => console.log("Annotations added successfully"))
                      .catch(error => console.log("Error adding annotations:", error));
                   
                  }
                }
              });

              // Note: You can also return the JSON data from this function if needed.
            });
          };
          
          
          // adobeDCView.registerCallback(
          //   window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
          //   function() {
          //     return new Promise((resolve, reject) => {
          //       resolve({
          //         code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
          //         data: profile
          //       });
          //     });
          //   }
          // );

          adobeDCView.registerCallback(
            AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
            function () {
              console.log('GET_USER_PROFILE_API callback');
              return new Promise((resolve, reject) => {
                console.log('Resolving promise with profile:', profile);
                resolve({
                  code: AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                  data: profile,
                });
              });
            },
            {}
          );
          
                  
          
          console.log('pr',profile)

          // Use the handleAnnotationEvents function when initializing the viewer
          adobeDCView.previewFile(
            {
              content: { location: { url: pdfUrl } },
              metaData: { fileName: pdfFileName, id: docId },
              userProfile: profile,
            },
            viewerConfig
          ).then(adobeViewer => {
            handleAnnotationEvents(adobeViewer);
          });


          if (pagenumber) {
            adobeDCView.previewFile(
              {
                content: { location: { url: pdfUrl } },
                metaData: { fileName: pdfFileName, id: docId },
              },
              viewerConfig
            ).then(adobeViewer => {
              handleAnnotationEvents(adobeViewer);
              adobeViewer.getAPIs().then(apis => {
                apis.gotoLocation(parseInt(pagenumber), 0, 0)
                  .then(() => console.log("Successfully landed on page", pagenumber))
                  .catch(error => console.log(error));
              });
            });
          }
          else {
            // No page number provided, normal preview
            adobeDCView.previewFile(
              {
                content: { location: { url: pdfUrl } },
                metaData: { fileName: pdfFileName, id: docId },
              },
              viewerConfig
            ).then(adobeViewer => {
              handleAnnotationEvents(adobeViewer);
            });
          }
        }
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    };

    // Load the PDF viewer when the component mounts
    loadPDFViewer();
  }, [pdfFileName, pdfUrl, docId, pagenumber, index]);

  return (
    <div style={{ height: '100vh', margin: 0, padding: 0 }}>
      <div id="adobe-pdf-viewer" style={{ height: '100%' }}></div>
      <Snackbar
                mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
    </div>
  );
};

export default PDFViewer;
