



import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import clipboardCopy from 'clipboard-copy';

import ClearIcon from '@mui/icons-material/Clear';
import './QueryShare.css';
import Menu from '../../Includes/Menu';
import Appbar from '../../Includes/Appbar';

import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';

import { Link } from "react-router-dom";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import {
  filterAndSearchQuery,undoFeedback, getFeedbackOptions,fetchQueryHistoryById,submitFeedbackOnAnswer
} from "../ApiHelper/QuerySearchApi.js"
import {getDepartment} from '../ApiHelper/DocumentSearchapi';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Divider from '@mui/material/Divider';

import CheckIcon from '@mui/icons-material/Check';
import Skeleton from '@mui/material/Skeleton';
import { useParams, useLocation } from 'react-router-dom';

export default function QueryShare() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  let conversation_id;
  
  for (const param of params.entries()) {
    // param is an array [name, value]
    const paramName = param[0];
    const paramValue = param[1];
  console.log(paramName);
    // Check if paramValue is a valid conversation_id (you can customize this validation)

      conversation_id = paramName;
   
  }
    console.log( 'convid', conversation_id)
    
   
    
    const [userpicture, setuserpicture] = useState("picture");
    const [showStaticContent, setShowStaticContent] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
   
    const [usertoken, setusertoken] = useState("");
    const [user_id, setuser_id] = useState("");
    const [userorg_id, setuserorg_id] = useState("");
    const [checkboxData, setCheckboxData] = useState([]);
    // Define the checkbox data state
    const [searchQuery, setSearchQuery] = React.useState("");
    const [userconversation_id, setuserconversation_id] = useState(); // Define the checkbox data state
    const handleNewQueryClick = () => {
      console.log('New Query Clicked');
     
    };
    useEffect(() => {
        setIsLoading(true);
        console.log('con',conversation_id)
        if (!conversation_id) {
          return;
        }
      
        const fetchData = async () => {
          setIsLoading(true);
         setuserconversation_id();
      
          try {
            const userData = await localStorage.getItem('userdata');
            const userDataObj = JSON.parse(userData);
      
            if (!userDataObj || !userDataObj.token) {
              console.error('User token not found in AsyncStorage or component unmounted.');
              return;
            }
            const picture = userDataObj.picture;
        
            setuserpicture(picture);
            const { token: userToken, user_id: deepuser_id, org_id: deeporg_id } = userDataObj;
      
            setusertoken(userToken);
            setuser_id(deepuser_id);
            setuserorg_id(deeporg_id);
      
            try {
              const res = await fetchQueryHistoryById(deepuser_id, deeporg_id, userToken, conversation_id);
      
              if (res.code === '200') {
                setuserconversation_id(conversation_id);
            // console.log('data:',data)
                setShowStaticContent(false);
                const updatedResults = {
                  ...res,
                  data: res.data.map((item) => ({
                    ...item,
                    answers: item.answers.map((answer) => ({
                      ...answer,
                      answer: (
                        <div dangerouslySetInnerHTML={{ __html: answer.answer.replace(/<br>/g, '<br/>') }} />
                      ),
                      context: (
                        <div dangerouslySetInnerHTML={{ __html: answer.context.replace(/<br>/g, '<br/>') }} />
                      ),
                      // Add plain text versions for copying
                      plainAnswer: answer.answer.replace(/<br>/g, '\n'),
                      plainContext: answer.context.replace(/<br>/g, '\n'),
                    })),
                  })),
                };
                setSearchResults((prevResults) => [...updatedResults.data, ...prevResults]);
              } else {
                setShowStaticContent(false);
              }
            } catch (error) {
              console.error('Error fetching query history by ID:', error);
            }
          } catch (error) {
            console.error('Error:', error);
          } finally {
            setIsLoading(false);
          }
        };
      
        fetchData();
      
      }, [conversation_id]);
      const clearSearchQuery = () => {
        setSearchQuery('');
      };
    return (
<Box sx={{ display: 'flex', marginLeft: '10px', paddingLeft: '10px', maxWidth: '100%' }} >
<CssBaseline />
<Appbar />
<Menu onNewQueryClick={handleNewQueryClick} />
<Box
  component="main"

  sx={{ flexGrow: 10, bgcolor: '#eff1f3', p: 3, minHeight: '100vh', textDecoration: 'none', "& fieldset": { border: 'none' }, }}
>
  <Toolbar />
    
    
    
    
    
    
    
    {isLoading ? (
    <div>
     <div>
            <div style={{ marginLeft: '30px', marginRight: '30px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box sx={{ margin: 1, marginLeft: 0 }}>
   
                  <Skeleton variant="circular" width={40} height={40} />
   
   
                </Box>
                <Box sx={{ width: '100%' }}>
   
                  <Skeleton width="100%">
                    <Typography>.</Typography>
                  </Skeleton>
   
                </Box>
              </Box>
   
              <Skeleton variant="rounded" width="100%" height='250px' >
                <div style={{ paddingTop: '50%', height: '30%' }} >
   
                </div>
              </Skeleton>
   
            </div>
          
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
              <Box sx={{ margin: 1, marginTop: '10px', marginLeft: '30px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box sx={{ margin: 1, marginTop: '10px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box sx={{ margin: 1, marginTop: '10px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box sx={{ margin: 1, marginTop: '10px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
            </Box>
            </div>
            <div>
            <div style={{ marginLeft: '30px', marginRight: '30px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', }}>
                <Box sx={{ margin: 1, marginLeft: 0 }}>
   
                  <Skeleton variant="circular" width={40} height={40} />
   
   
                </Box>
                <Box sx={{ width: '100%' }}>
   
                  <Skeleton width="100%">
                    <Typography>.</Typography>
                  </Skeleton>
   
                </Box>
              </Box>
   
              <Skeleton variant="rounded" width="100%" height='250px' >
                <div style={{ paddingTop: '50%', height: '30%' }} >
   
                </div>
              </Skeleton>
   
            </div>
          
            <Box sx={{ display: 'flex', alignItems: 'center', }}>
              <Box sx={{ margin: 1, marginTop: '10px', marginLeft: '30px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box sx={{ margin: 1, marginTop: '10px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box sx={{ margin: 1, marginTop: '10px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <Box sx={{ margin: 1, marginTop: '10px' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
            </Box>
            </div>
    </div>
   ) : (
    <div>
      {searchResults.map((result, index) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '25px', textAlign: 'left' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', justifyItems: 'flex-start' }}>
              
              <img src={userpicture} alt="User" width={30} height={30} style={{ borderRadius: '50%' }} />
    <span style={{ fontSize: '1.1em', marginLeft: '10px', marginBottom: '10px' }}>{result.query}{" "}</span>
              </div>
           
              <div className="rectangle-2 rectangle-3" sx={{ color: 'rgba(0, 0, 0, 0.6);', borderRadius: '1rem' }} >
              {result.answers.map((answer, answerIndex) => (
   
             <>     <Typography key={answerIndex}>
   
   
             <div className='card-item_2 ' style={{ color: "#606060", fontSize: '14px', paddingLeft: '0px' }}>{answer.answer}</div>
             <Typography sx={{ marginLeft: '10px' }}>
               <IconButton>
                 < PictureAsPdfIcon style={{ fontSize: '1.2rem' }} />
               </IconButton>
               <Link to='#' style={{ color: '#2F80ED', fontSize: '14px' }}>{answer.doc_name}</Link>
               <div style={{ display: 'flex' }}>
                
                
                
                 
   
   
   
   
   
               </div>
             </Typography>
   
           </Typography>
           {answerIndex < result.answers.length - 1 && <Divider sx={{ margin: '10px' }} />}
           </>
                ))}
              </div>
            
            </Box>
             ))}
    </div>
   )}
   </Box>
   </Box>
    )
   };