import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import {CssBaseline , Drawer,AppBar} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Menu from '../../Includes/Menu';
import Appbar from '../../Includes/Appbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import HelpIcon from '@mui/icons-material/Help';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import './Faqs.css'
import MuiAccordionDetails from '@mui/material/AccordionDetails';
const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin:'5px',
    
  },
  accordion: {
    marginLeft: 'auto',
  },
};

const Faqs = (props) => {
  const [isRemoved, setIsRemoved] = useState(true);
  const [loading, setLoading] = useState(false);
  const [queryHistoryData, setQueryHistoryData] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const handleAccordionPress = (index) => {
    if (expanded === index) {
      setExpanded(null);
      setIsRemoved(true[index]);
    } else {
      setExpanded(index);
      setIsRemoved(false);
    }
  };
  const handleChange =
    (panel) => (event, newExpanded,newIcon) => {
      setExpanded(newExpanded ? panel : false);
      setIsRemoved(newIcon ? <AddCircleIcon/>:<RemoveCircleIcon/>);
    };
    const loadingSkeletonStyle = {
      width: '100%',
      height: '40px', // Adjust the height based on your design
      backgroundColor: '#e0e0e0', // Placeholder color
      borderRadius: '5px',
      margin: '10px 0',
    };
    const handleNewQueryClick = () => {
      console.log('New Query Clicked');
     
    };
    const FaqSkeleton = () => (
      // Render a simple loading skeleton placeholder
      <div style={loadingSkeletonStyle}></div>
    );
 
    const fetchfaqs = async () => {
      try {
        setLoading(true);
        const userData = await localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        if (!userDataObj || !userDataObj.token) {
          console.error("User token not found in AsyncStorage.");
          return;
        }
        const userToken = userDataObj.token;
        const deepuser_id = userDataObj.user_id;
        const deeporg_id = userDataObj.org_id;
  
       
       
        const apiEndpoint = "faqs";
        const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
        const requestData = {
          user_id: deepuser_id,
          org_id: deeporg_id,
         
        };
  
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            token: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
  
        const data = await response.json();
       
  
        if (data.code === "200") {
          setQueryHistoryData(data.data);
         setLoading(false);
          if (data.data.length === 0) {
         
          } else {
             
             setLoading(false);
          }
        } else {
           console.error('Error:', data.message);
           setLoading(false);
         
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
      //use effect to call fetchfaqs function when page reload or navigate to faq page.
    useEffect(() => {
      fetchfaqs();
    }, []);

    const { windows } = props;
    const drawerWidth = 322;
    const drawer = (
      <div>
    <Menu onNewQueryClick={handleNewQueryClick} />
      </div>
    );
    const container =
      windows !== undefined ? () => window().document.body : undefined;
      const [mobileOpen, setMobileOpen] = React.useState(false);

  return (
    <>

      <Box sx={{ display: 'flex' }} >
        <CssBaseline />
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white",
          }}
        >
          <Appbar />
        </AppBar>

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            // onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 10, bgcolor: '#eff1f3', p: 3, minHeight: '100vh' }}
        >
          <Toolbar />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop:'10px', textAlign: 'left' }}>
          {loading ? (
                // Render the skeleton for each FAQ accordion item
                Array.from({ length: 5 }).map((_, index) => <FaqSkeleton key={index} />)
              ) : (
            <div display='flex'  >
            {queryHistoryData.map((item, index) => (
                <Card style={styles.card}>
                
                  
                
              <Accordion expanded={expanded === index} onChange={handleChange(index)} style={styles.accordion}>
             
                    <AccordionSummary className={expanded === index ? 'active' : ''}>
                    <IconButton>
                        <HelpIcon sx={{borderRadius:'50px'}} className={expanded === index ? 'active' : ''}/>
                      </IconButton>
                    <Typography sx={{marginTop:'10px',width:'87%'}}>{item.question}</Typography>
                      <IconButton className='desktop_view_icon'>
                        {expanded === index? <RemoveCircleIcon sx={{borderRadius:'50px'}} className={'active'}/>:<AddCircleIcon/>}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider sx={{margin:'0px',padding:'0px'}}/>
                      <Typography sx={{marginTop:'10px',marginLeft:'40px'}}>
                        {item.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
               
              </Card>
                
              ))}
            </div>
             )}
          </Box>
          


          <Toolbar /><Toolbar /><Toolbar />
        </Box>

      </Box>

    </>
  )
};
export default Faqs;