import React, { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import './SignIn.css'
import { Link } from 'react-router-dom';
import { Grid, Box, Paper, TextField, Button, Typography,  Toolbar } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import image1 from './image-1.png';
import image2 from './footer.svg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Alert from '@mui/material/Alert';
import DocSearch from '../Query/Query';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { newPassword } from '../ApiHelper/Profile'
// const validationSchema = Yup.object({
//     username: Yup.string().trim().required('Username is required'),
//     password: Yup.string().required('Password is required'),
// });
const NewPassword = () => {
    const location = useLocation();
    const [reset_token ,setReset_token] = useState('')
    const urlParams = new URLSearchParams(location.search);
    const tokenFromUrl = urlParams.get('token');
    
    console.log('urlParams',urlParams)
    console.log('tokenFromUrl',tokenFromUrl)
    const [logoutMessage, setLogoutMessage] = useState('');

    useEffect(() => {
        // Retrieve the message from local storage
        const storedMessage = localStorage.getItem('logoutmessage');
        if (storedMessage) {
            setLogoutMessage(storedMessage);

            // Clear the message from local storage after retrieving it
            localStorage.removeItem('logoutmessage');
        }
    }, []);
    console.log(logoutMessage)
    const [loading, setLoading] = useState(false);
    const [toastdisplay, settoastdisplay] = useState('no');
    const [toastmessage, settoastmessage] = useState('');
    const [toastcolor, settoastcolor] = useState('info');
    const [severity, setseverity] = useState('success');
    const [secondSnackbarOpen, setSecondSnackbarOpen] = useState(true);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const initialValues = {
        username: '',
        password: '',
    };
    const mobileViewPaper = {
        width: "165px",
    }
    const paperStyle = {
        padding: '15px 29.5px',
        height: 'auto',
        width: "365px",
        margin: "25px auto 15px auto",
        gap: 32,
        borderRadius: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
        margintop: '90px'
    }
    const fontStyle = {
        font: "400 14px Roboto, sans-serif",
        padding: 32,


    }
    const mobilefontStyle = {
        font: "400 12px Roboto, sans-serif",
        padding: 12,


    }
    const btnStyle = {
        marginTop: '30px',
        marginBottom: '17px'
    }
    const padStyle = {
        padding: '25px 0 0 0',
        font: "500 24px/26px Roboto, sans-serif",
    }
    const mobilepadStyle = {
        padding: '5px 0 0 0',
        font: "300 18px/20px Roboto, sans-serif",
    }
    const inputStyle = {
        borderRadius: '4px',
        gap: 32
    }
    const margintop = {
        marginTop: 30,
    }
    const forgotPass = {

        textDecoration: 'none',
        fontFamily: "Roboto, sans-serif",
    }


    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const [errorCheck, setErrorCheck] = useState(false);
    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(false);
        setLoading(true);
       if(values.newPassword == values.confirmPassword){
        try {
            const data = await newPassword(
                
                values.confirmPassword,tokenFromUrl
            );
            //  console.log(' bef inside')
            if (data.code === "200") {
                settoastdisplay('yes');
                settoastmessage(
                    <span>
                        Password Changed... <Link to="/" style={{color:'white',textDecoration:'none'}} >Click here to Login</Link>
                    </span>
                );
                settoastcolor('info');
                console.log(data.data);
                setSnackbarOpen(true);
                setLoading(false);
                // Use setTimeout to navigate after 10 seconds
                setTimeout(() => {
                    navigate('/', { state: { showSnackbar: true } });
                }, 10000); // 10000 milliseconds = 10 seconds
            }
            
            else {
                settoastdisplay('yes');
                settoastmessage(res.message);
                settoastcolor('error');
                setSnackbarOpen(true);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error:", error);
        }
       }
       else{
        setErrorCheck(true)
        setLoading(false);
       }
        
    };
    // useEffect(() => {
    //     // Retrieve the message from local storage

    //     localStorage.removeItem('message');

    // }, []);
    const mobileMediaQuery = '@media (max-width: 500px)';
    return (
        <>

            <Grid className="home-1920-login">

                <Grid sx={{ backgroundColor: 'var(--Neutral-grey, #EFF1F3)' }}>
                    <Formik
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values }) => (

                            <Form>
                                <Paper elevation={8} className="paper_style" sx={{
                                    ...paperStyle, [mobileMediaQuery]: {
                                        width: '95%', height: '350px', margin: '50% 10px 10px 10px'
                                    }
                                }}>
                                    <Grid align='center'>
                                        <img className="image-1" src={image1} />
                                        <div className="login" >Reset Password</div>
                                        <div className="strong_password" style={{padding:'15px 0px'}}>
                                        Strong password required. Enter 8-256 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.
                                        </div>
                                    </Grid>

                                    <div className="password_field">
                                        <Field as={TextField} name="newPassword"

                                            type={showPassword ? 'text' : 'password'}
                                            helperText={<ErrorMessage name="password" />}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockOpenIcon />
                                                    </InputAdornment>

                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={togglePasswordVisibility}>
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>

                                                    </InputAdornment>

                                                ),
                                            }}

                                            placeholder="New Password" fullWidth />
                                    </div>
                                    <div className="password_field">
                                        <Field as={TextField} name="confirmPassword"

                                            type={showConfirmPassword ? 'text' : 'password'}
                                            helperText={<ErrorMessage name="password" />}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockOpenIcon />
                                                    </InputAdornment>

                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={toggleConfirmPasswordVisibility}>
                                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>

                                                    </InputAdornment>

                                                ),
                                            }}

                                            placeholder="Confirm Password" fullWidth />
                                    </div>
                                    {
                                        errorCheck && <Typography sx={{color:'red',fontSize:'11px'}}>New password and Confirm password should be same</Typography>
                                    }
                                    {/* <div className="forgot_password">
                                        <Link to='#' style={forgotPass} >Forgot Password?</Link>
                                    </div> */}
                                    <div className="submit_btn">
                                        <Button type="submit" color="primary" variant="contained" style={{

                                            backgroundColor: loading ? '#616161' : btnStyle.backgroundColor,
                                        }} fullWidth disabled={loading}>  {loading && <CircularProgress size={16} style={{ position: 'flex-start', left: '70%', marginLeft: '-12px', color: 'white' }} />}
                                            Submit
                                        </Button>
                                    </div>



                                </Paper>

                            </Form>)}
                    </Formik>
                    <img className="footer_image" src={image2} />
                </Grid >
            </Grid>

            <Snackbar
                mode="outlined"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center', // Center bottom
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: toastcolor === 'black',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {toastmessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
            {logoutMessage &&
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={secondSnackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSecondSnackbarOpen(false)}
                >
                    <SnackbarContent
                        style={{
                            backgroundColor: 'info',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        message={logoutMessage}

                        action={[
                            <IconButton key="close" color="inherit" onClick={() => setSecondSnackbarOpen(false)}>
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            }


        </>
    );

}
export default NewPassword;