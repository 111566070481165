// API helper function to Fetches query history data based on user ID and organization ID.
export const fetchQueryHistory = async (
  user_id, userorg_id, querySearch, datelist, fromDate, toDate,token,index
) => {
  const BASE_URL = `${window.constants.api_url}/queryhistory`;
  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      searchstring: querySearch,
      date: {
        type: "1",
        value: datelist,
      },
      start_index: 0,
      limit: 50,
    };
    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const fetchMoreQueryHistory = async (
  user_id, userorg_id, querySearch, datelist, fromDate, toDate,token,index
) => {
  const BASE_URL = `${window.constants.api_url}/queryhistory`;
  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      searchstring: querySearch,
      date: {
        type: "1",
        value: datelist,
      },
      start_index: index,
      limit: 50,
    };
    if (fromDate !== "" && toDate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromDate},${toDate}`,
      };
    }
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//  API helper function to Deletes a query title associated with a user and organization.
export const deleteQueryTitle = async (
  user_id,
  userorg_id,
  userquery_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/deletequerytitle`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      query_id: userquery_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//  API helper function to editQueryTitle a query title associated with a user and organization.
export const editQueryTitle = async (
  user_id,
  userorg_id,
  userconversation_id,
  editedText,
  usertoken
) => {
  const BASE_URL = `${window.constants.api_url}/editquerytitle`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      query_id: userconversation_id,
      query_title: editedText,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: usertoken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//  API helper function to Filters and searches for queries based on user-provided criteria.
export const filterAndSearchQuery = async (user_id, userorg_id, searchQuery, selectedDepartments, userconversation_id, usertoken) => {
  const BASE_URL = `${window.constants.api_url}/${window.constants.search_method_name}`;

  try {
    const userData = {
      user_id,
      org_id: userorg_id,
      query_text: searchQuery,
      department: selectedDepartments, // Pass the selected department IDs as a filter
      conversation_id: userconversation_id,
    };
console.log(userData)
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: usertoken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//   API helper function to Fetches feedback options based on the specified criteria.
export const getFeedbackOptions = async (userorg_id, user_id, conversationId, answerId, feedbackType, usertoken) => {
  const BASE_URL = `${window.constants.api_url}/getfeedbackoptions`;

  try {
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: usertoken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        org_id: userorg_id,
        user_id,
        conversation_id: conversationId,
        answer_id: answerId,
        feedback_type: feedbackType,
      }),
    });

    const data = await response.json();

    if (data.code == 200) {
      return data;
    } else {
      throw new Error("Error fetching feedback options");
    }
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//   API helper function to Initiates the process to undo feedback for a specific answer in a conversation.
export const undoFeedback = async (userorg_id, user_id, conversationId, answerId, token) => {
  const BASE_URL = `${window.constants.api_url}/undo-feedback`;

  try {
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        org_id: userorg_id,
        user_id,
        conversation_id: conversationId,
        answer_id: answerId,
      }),
    });

    const data = await response.json();

    if (data.code == 200) {
      return data;
    } else {
      throw new Error("Error undoing feedback");
    }
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
//   API helper function to Fetches query history based on a specific conversation ID.
export const fetchQueryHistoryById = async (user_id, userorg_id, userToken, conversation_id) => {
  const BASE_URL = `${window.constants.api_url}/queryhistorybyid`;

  try {
    const requestData = {
      conversation_id,
      org_id: userorg_id,
      user_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: userToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
// API helper function to Submits feedback on a selected answer.
export const submitFeedbackOnAnswer = async (
  user_id,
  userorg_id,
  userToken,
  selectedConversationId,
  selectedAnswerId,
  selectedFeedbackType,
  optimumanswer,
  adfeetback,
  selectedOptions
) => {
  const BASE_URL = `${window.constants.api_url}/submitfeedbackonanswer`;

  try {
    const userData = {
      org_id: userorg_id,
      user_id,
      conversation_id: selectedConversationId,
      answer_id: selectedAnswerId,
      feedback_type: selectedFeedbackType,
      remarks: optimumanswer,
      other_remarks: adfeetback,
      options: selectedOptions,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: userToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
