const filteredSuggestions = [
    "What is an offence relating to bribing a public servant?",
    "What is a bribery offence?",
    "What is the punishment for abetment of offences?",
    "What is a punishment for a habitual offender?",
    "What is the punishment for an attempt to fix a fine?",
    "What is a presumption where public servant accepts any undue advantage?",
    "The Code of Criminal Procedure, 1973 to apply subject to what?",
    "What is section 13 (J) (a) of the Criminal Code?",
    "In what year was the Prevention of Corruption Act enacted?",
    "Who is empowered to prepare, publish, maintain or revise an electoral roll?",
    "Who is the president, secretary or other office-bearer of a registered co-operative society engaged in agriculture, industry, trade or banking?",
    "Who is receiving or having received any financial aid from the Central Government or a State Government?",
    "What was omitted by Act 34 of 2019, s. 95 and the Fifth Schedule?",
    "How is legal remuneration defined?",
    "What is included in a public servant's remuneration?",
    "What are public servants, whether appointed by the Government or not?",
    "What shall be understood of every person who is in possession of the situation of a public servant?",
    "What is the power to appoint special judges?",
    "Who may appoint as many special Judges as may be necessary?",
    "What may the Central Government or the State Government do?",
    "What is an offence punishable under this Act?",
    "What is a person not qualified for appointment as a special Judge?",
    "What is a special judge qualified for under this Act?",
    "What is the Code of Criminal Procedure, 1973?",
    "Who can triage cases?",
    "What shall be tried by special Judges?",
    "In what year was the Criminal Procedure Act passed?",
    "What is the time limit for the trial of an offence?",
    "How long does it take for a trial to be concluded?",
    "When the trial is not concluded within the said period, what shall the special Judge record?",
    "What may be extended by the said further period, for reasons to be recorded in writing but not exceeding six months at a time?",
    "How long may a special judge take cognizance of offences without the accused being committed to him for trial?",
    "What is the procedure for trial of warrant cases by Magistrates?",
    "What does the Code of Criminal Procedure prescribe?",
    "Under what section of the Code of Criminal Procedure, 1973 (2 of 1974), be deemed to have been tendered under section 307 of that Code?",
    "Except as provided in sub-section (/), what provisions shall, so far as they are not inconsistent with this Act?",
    "What is a Court of Session for the purposes of this Act?",
    "Who conducts a prosecution before a special Judge?",
    "What is the Criminal Law Amendment Ordinance, 1944 called?",
    "What is a District Judge's power to try summarily?",
    "What is an offence relating to bribing a public servant?",
    "What is a bribery offence?",
    "What is an offence relating to bribing a public servant by a commercial organisation?",
    "What is a penalty for a bribery offence committed by commercial organisations?",
    "Who can be fined for bribes of public servants?",
    "What shall be punishable with fine?",
    "Who gives or promises to give an undue advantage to a public servant?",
    "What shall be a defence for a commercial organisation to prove that it had in place adequate procedures in compliance with such guidelines as may be prescribed to prevent persons associated with it from undertaking such conduct?",
    "What is a person said to give or promise to give any undue advantage to a public servant if he is alleged to have committed the offence under section 8?",
    "What does a person have been prosecuted for?",
    "What is a commercial organisation for the purposes of section 8 and this section?",
    "What is a partnership firm or any association of persons formed in India and which carries on a business whether in India or outside India?",
    "What does business include?",
    "What is a person said to be associated with?",
    "What constitutes an offence under sub-section (/)?",
    "What is the capacity in which a person performs services for or on behalf of the commercial organisation?",
    "Who performs services for or on behalf of a commercial organisation?",
    "What shall not matter regardless of whether the person is employee or agent or subsidiary of such commercial organization?",
    "Who determines a person who performs services for or on behalf of a commercial organisation?",
    "What is to be determined by reference to all the relevant circumstances?",
    "What shall be presumed if a person is an employee of a commercial organisation?",
    "What is the offence under the Code of Criminal Procedure, 1973?",
    "What is the offence under sections 7A, 8 and this section cognizable?",
    "Who shall the Central Government prevent from bribing a public servant?",
    "Who is prohibited from bribing a public servant?",
    "What can be put in place for compliance by such organisations?",
    "Who is guilty of offence under section 9?",
    "What is an offence committed by a commercial organisation?",
    "What is the offence proved to have been committed with the consent or connivance of?",
    "Who shall be guilty of the offence and be liable to be proceeded against?",
    "What shall be punishable with imprisonment for a term which shall not be less than three years but which may extend to seven years?",
    "Who shall also be liable to fine?",
    "For the purposes of this section, what means a partner in a firm?",
    "Who is a public servant?",
    "What does being a public servant accept or obtain?",
    "What does he attempt to obtain for himself or for any other person?",
    "Who is concerned in any proceeding or business transacted or about to be transacted by a public servant?",
    "Who has any connection with the official functions or public duty of himself or any public servant to whom he is subordinate?",
    "Who shall be punished with imprisonment for a term of not less than six months?",
    "What may extend to five years?",
    "Who shall also be liable to imprisonment?",
    "Who may extend to five years and be liable to fine?",
    "What shall a person who abets any offence punishable under this Act be punished with?",
    "What is a term which may extend to seven years and be liable to fine?",
    "What is the term that shall not be less than three years?",
    "Who omitted the words or agrees to accept by s. 5?",
    "What does s. 5 omit?",
    "What is a public servant said to commit the offence of?",
    "Who commits the offence of criminal misconduct?",
    "Who is guilty of dishonestly or fraudulently misappropriating or otherwise convert for his own use any property entrusted to him or any property under his control as a public servant or allows any other person to do?",
    "What happens if a person intentionally enriches himself illicitly during the period of his office?",
    "A person shall be presumed to have intentionally enriched himself what?",
    "What is the term for income received from any lawful sources of income?",
    "What is a public servant punishable with?",
    "What shall a public servant be liable for if he commits criminal misconduct?",
    "What is a term of imprisonment for a person who commits a criminal misconduct not less than four years but may extend to 10 years?",
    "Who may be convicted of an offence punishable under this Act?",
    "What is the term for which a person commits an offence punishable under this Act?",
    "What is a term of imprisonment that may extend to ten years?",
    "How long is the sentence for imprisonment?",
    "Whoever attempts to commit the offence referred to by this Act is punishable by what?",
    "Who attempts to commit an offence referred to in sub-section (/) of section 13 is punishable with imprisonment for a term Stwhich shall not be less than two years but which may extend to five years?",
    "What does the court take into consideration when fixing a fine?",
    "What is a sentence of fine imposed under?",
    "What shall the court take into consideration in fixing the amount of the fine?",
    "What is the value of the property if any that the accused person obtained by committing the offence?",
    "In what section of the ACT is an offence referred to as a pecuniary resource or property?",
    "Who is unable to account satisfactorily for the offence?",
    "What is a person authorised to investigate under ACT 17?",
    "What does the Code of Criminal Procedure contain?",
    "What is the rank of an Inspector of Police in the Delhi Special Police Establishment?",
    "What section of the Code of Criminal Procedure did the Assistant Commissioner of Police investigate?",
    "What is sub-section (/) of Act 16 of 2018?",
    "What shall be deemed to have been attached by the Investigating Officer in the case of immovable property?",
    "What shall be deemed to have been produced before the Designated Authority when the Investigating Officer notifies his report and places it at the disposal of the designated authority?",
    "Any person aggrieved by an order under the proviso to sub-section (1) may apply to what authority for grant?",
    "Who may apply to the Designated Authority for grant of permission to transfer or otherwise deal with property?",
    "Who may either grant or refuse to grant the permission to the applicant?",
    "Who shall have all the powers of a civil court required for making a full and fair enquiry into the matter before it?",
    "Who may appeal an order made by the Designated Authority under sub-section (3) or (5) of this Act?",
    "What may the Designated Authority do within one month from the date of receipt of the order?",
    "Who may confirm the order of attachment of property or seizure so made or revoke the order made?",
    "How long does it take for a Special Court to revoke an order to release the property?",
    "What is the time limit for releasing the property after the Special Court is satisfied about the seizure or attachment?",
    "What may the Special Court do if it is satisfied about a seizure or attachment?",
    "Who may order forfeiture of property if the person from whose possession it is seized or attached is prosecuted?",
    "What shall be competent for the Special Court to make an order in respect of property seized or attached?",
    "What may be specified in the notice against the grounds of forfeiture?",
    "What is a bona fide transferee of property for value without knowing that such property has value?",
    "How long can a person who is aggrieved by an order of the Special Court appeal to the High Court of Jammu and Kashmir?",
    "What is a reasonable interest calculated from the date of seizure of the property?",
    "Who has the power to inspect bankers' books?",
    "What is a police officer empowered to investigate under section 17?",
    "What is required to inspect any banker books?",
    "In what year was the Prevention of Money Laundering Act passed?",
    "In what year did the Criminal law amendment Ordinance come into effect?",
    "What provisions of the Criminal Law Amendment Ordinance, 1944 shall have effect for the purposes of this Act?",
    "What is the Code of Criminal Procedure?",
    "What is the Code of Criminal Procedure, 1973?",
    "What is a reason for a Court to reverse a judgment passed by a special judge?",
    "What was the amendment of the Ordinance 38 of 1944?",
    "What shall be substituted for the words State Government wherever they occur?",
    "What shall be substituted for the words \u201cthree months\u201d in section 10?",
    "How many days may be comprised in one session or in two or more successive sessions?",
    "Before the expiry of the session immediately following the session or the successive sessions, for a total period of how long?",
    "What happens if both Houses agree in making any modification in the rule?",
    "If both houses agree that the rule should not be made, the rule shall have effect only in what form?",
    "What does the Prevention of Corruption Act do?",
    "What was omitted from Act 45 of 1860?",
    "What was the Repealing and Amending Act, 2001(30 of 2001), s.2 and the First Schedule?",
    "What year was the INDIAN CONTRACT ACT passed?",
    "What is the power to set the terms of agreements?",
    "What is the power to set aside contract induced by undue influence?",
    "What is void where both parties are under mistake as to matter of fact?",
    "What is a promise to pay a debt?",
    "What does proceeding void refer to?",
    "What does saving a guarantee agreement of a bank or financial institution refer to that has already arisen?",
    "What is not affected by Section 294A of the Indian Penal Code?",
    "What is the definition of a contingent contract?",
    "When is enforcement of contracts contingent on an event happening?",
    "What is the effect of refusal of party to perform promise wholly?",
    "What is the effect of accepting performance from a third person?",
    "What is the place for performance of promise, where no application to be made and no place fixed for performance?",
    "What are reciprocal promises?",
    "What is prescribed or sanctioned by promisee?",
    "What is the order of performance of reciprocal promises?",
    "What is a contract consisting of reciprocal promises?",
    "What is the term for acceptance of performance at a time other than that agreed upon?",
    "What is another term for agreement to do impossible act?",
    "What is the effect of novation, rescission, and alteration of a contract?",
    "Who may dispense with or remit performance of promise?",
    "What is a void agreement?",
    "Who is liable for loss or damage caused by breach of contract?",
    "What is compensation for failure to discharge obligation resembling those created by contract?",
    "What is the definition of a contract of indemnity and guarrantee?",
    "What is a term for a principal debtor and a creditor?",
    "What is a suretys liability?",
    "What is discharged when creditor compounds with, gives time to, or agrees not to sue, principal debtor?",
    "What is a guarantee on contract that creditor shall not act on it until co-surety joins?",
    "What is an invalid guarantee obtained by misrepresentation?",
    "What are the terms bailment, baillor, and bailee defined as?",
    "How is a bailor repaid of necessary expenses?",
    "What is a bailees responsibility when goods are not duly returned?",
    "What is the general lien of bankers, factors, wharfingers, attorneys and policy-brokers?",
    "What is Presumption in case of subsequent advances?",
    "What is Pawnees right as to extraordinary expenses incurred?",
    "Where pawnor makes default, what is pledge by mercantile agent?",
    "How can ratification of unauthorized act forming part of a transaction?",
    "Who may revoke an agents authority?",
    "What is the term for a termination of agency?",
    "Who may be compensated for revocation by principal?",
    "What may revocation and renunciation be expressed or implied?",
    "What happens when termination of agents authority takes effect as to agent and as to third persons?",
    "What is a principals right to benefit gained by agent dealing on his own account?",
    "What is principals right to benefit gained by agent dealing on his own account?",
    "What is agents duty to pay sums received for principal?",
    "Who is not entitled to remuneration for business misconduct?",
    "What is agents lien on principals property?",
    "Who is to be indemnified against consequences of lawful acts?",
    "What is non-liability of employer of agent to do a criminal act?",
    "What is compensation to agent for injury caused by principals neglect?",
    "Principal how far bound, when agent exceeds authority?",
    "What rights of parties to a contract made by agent are not disclosed?",
    "What is the right of person dealing with agent personally liable?",
    "What are the consequences of inducing agent or principal to act on belief that principal or agent will be held exclusively liable for?",
    "Who is liable for misrepresentation or fraud by an agent?",
    "What was the date of the INDIAN CONTRACT ACT?",
    "What shall not affect the provisions of any Statute, Act or Regulation?",
    "What year was the Transfer of Property Act passed?",
    "In what year did C.P. Act 1 come into effect?",
    "What Act extends the Transfer of Property Act to Berar?",
    "In what year did the Berar Laws Act pass to Goa, Daman and Diu?",
    "What year was Reg. 8 passed to Laccadive, Minicoy and Amindivi Islands?",
    "What year did Act 26 pass to Pondicherry?",
    "In what year was the Sonthal Parganas Settlement Regulation passed?",
    "How was the Sonthal Parganas Justice and Laws Regulation amended in 1899?",
    "What was the Panth Piploda Law Regulation in 1929?",
    "In what year was the Scheduled Districts Act enacted?",
    "How many Scheduled Districts Acts were in force in 1874?",
    "Where are the districts of Hazari bagh, Lohardaga and Manbhum located?",
    "What Act of 1951, s. 3 and Sch., omitted the words except the State of Jammu and Kashmir?",
    "What is the date of the Fifth Schedule?",
    "What is the term for abstinence or promise?",
    "An agreement not enforceable by law is said to be what?",
    "What is section 5 of Indian contract Act, 1872?",
    "What is the Indian contract Act, 1872 referred to as?",
    "How is a proposal revoked?",
    "How is fraud defined in sections 17 and 18?",
    "What are the provisions of sections 20, 21 and 22 of the Indian Constitution?",
    "What is the term used to describe the committing, or threatening to commit, any act forbidden by the Indian constitution?",
    "What was the name of the act that subs. by Act 3 of 1951?",
    "What does Fraud mean?",
    "What is a void agreement?",
    "What is the obligation of a person enjoying benefit of non-gratuitous act?",
    "Who is bound to make compensation to when someone does something lawfully for another person?",
    "Who is bound to make compensation to the former in respect of or to restore the thing so done or delivered?",
    "Who is entitled to receive compensation for loss or damage caused by breach of contract?",
    "What compensation is not to be given to a party who has broken a contract?",
    "What must be taken into account in estimating the loss or damage arising from a breach of a contract?",
    "What must the means which existed of remedying the inconvenience caused by the non-performance of the contract take into account?",
    "What is the name of the act that was passed by Congress in 1930?",
    "What is a term for a sale defined by the Indian Sale of Goods Act?",
    "What is a sellers obligation to do in order to ascertain a certain price?",
    "What does a seller do to a certain price?",
    "What is the term for the completion of a sale when goods are uncertain at date of contract?",
    "What is a transfer of ownership of moveable property when sold together with immovable?",
    "What is a sellers lien where payment to be made at a future day, but no time fixed for delivery?",
    "What is a sellers lien against subsequent buyer?",
    "What is implied warranty of goodness or quality?",
    "What is sellers responsibility for badness of title?",
    "What is a warranty implied where goods are sold as being of a certain denomination?",
    "What is an implied warranty implied on sale of provisions?",
    "What is a warranty where goods ordered for a specified purpose?",
    "What is the warranty on sale of article of well known ascertained kind?",
    "Who may refuse to accept if goods not ordered are sent with goods ordered?",
    "What is right of seller as to rescission, on failure of buyer to pay price at time fixed?",
    "What was the Indian Sale of Goods Act, 1930?",
    "What is the effect of using pretended biddings to raise price?",
    "What is a contract by which one party promises to save the other from loss caused to him by conduct of the promisor himself or by the conduct of any other person?",
    "What is the term for a contract of indemnity?",
    "What does death of surety operate as in the absence of any contract to the contrary?",
    "What is a revocation of a continuing guarantee so far as regards future transactions?",
    "What does not discharge surety?",
    "How is a guarantee obtained by misrepresentation invalid?",
    "What is the term for a guarantee obtained by concealment?",
    "What is an example of an invalid guarantee?",
    "What is another name for the person to whom the goods are delivered?",
    "What does a person already in possession of the goods of another contract to hold them as a bailee?",
    "Who becomes the bailor of such goods?",
    "What may not have been delivered by way of bailment?",
    "What is the duty of a bailee to disclose faults in goods bailed?",
    "What is the duty of the bailor to disclose to the bailee faults in goods bailed?",
    "The bailor is bound to disclose faults that materially interfere with the use of what?",
    "Who is responsible for damage arising to the bailee directly from faults?",
    "What is the bailor responsible for if goods are bailed for hire?",
    "Who lends a horse to B?",
    "Who is responsible to A for damage sustained?",
    "What does A know to be vicious?",
    "A is responsible to B for what?",
    "A hires a carriage of B. The carriage is unsafe, though B is not aware of it, and who is responsible for the injury?",
    "What is the bailee bound to do in all cases of bailment?",
    "What is a bailee bound to take as much care of the goods bailed to him as a man of ordinary prudence would take of his own goods of the same bulk, quality and value?",
    "What is not responsible for the loss, destruction or deterioration of the thing bailed?",
    "What is the term for the termination of bailment by a bailee?",
    "What is a contract of bailment avoidable at the option of the bailor if the bailee does any act with regard to the goods bailed, inconsistent with the conditions of a bailment?",
    "Who lets a horse for his own riding?",
    "Who drives the horse in his carriage?",
    "What is a termination of the bailment?",
    "What is a bailee liable for if he makes any use of the goods bailed that is not according to the conditions of the bailment?",
    "Who is responsible for any damage arising to the goods from or during such use of goods?",
    "What was the Madras Port Trust Act, 1905 known as?",
    "What is the name of the act that declared the responsibility of the Trustees of the Port of madras to be?",
    "What is the meaning of \u201cin the absence of any special contract\u201d in s. 152 of the Indian Railways Act, 1890?",
    "What is a common carriers liability in the Carriers Act of 1865?",
    "The Carriers Act, 1865 (3 of 1865), s. 8. 39 Illustrations (a)A lends a horse to who?",
    "B allows C, a member of his family, to ride the horse.",
    "C rides with care, but what happens to the horse?",
    "Who is liable to make compensation to A for the injury done to the horse?",
    "A hires a horse in Calcutta from B expressly to march to Benares.",
    "A rides with due care but marches to what instead?",
    "What is A liable to make compensation to B for the injury to the horse?",
    "What is the effect of mixture of goods with bailees?",
    "What shall the bailor and bailee have an interest in proportion to their respective shares in the mixture thus produced?",
    "What is the effect of mixture without bailors consent?",
    "Who mixes the goods of the bailor with his own goods?",
    "Who is bound to bear the expense of separation or division and any damage arising from the mixture?",
    "A bails 100 bales of cotton marked with a particular mark to who?",
    "B mixes the bale with what other bale of his own?",
    "A is entitled to have what returned?",
    "Who is entitled to have his 100 bales returned?",
    "Who is bound to bear all the expense incurred in the separation of the bale?",
    "What does the bailee, without the consent of the bailor, mix the goods of?",
    "Who is entitled to be compensated by the bailee for the loss of the goods?",
    "What is impossible to separate the goods bailed from the goods of the bailor?",
    "How much is a barrel of Cape flour worth?",
    "What is the value of the flour B mixes with without As consent?",
    "Who must compensate A for the loss of his flour?",
    "Who must reimburse A for his flour loss?",
    "What must the bailee receive?",
    "Who is to receive no remuneration?",
    "Who shall repay to the bailee the necessary expenses incurred by him for the purpose of bailment?",
    "Who may at any time require the return of the thing lent?",
    "What if the loan was gratuitous even though he lent it for a specified time or purpose?",
    "What must the lender indemnify the borrower for if he compels the return of the thing lent before the time agreed upon would cause him loss exceeding the benefit actually derived from the loan?",
    "What is the duty of the bailee to return the goods bailed, without demand?",
    "When goods are not returned, delivered or tendered at the proper time, what is the bailees responsibility?",
    "What does the Madras Port Trust Act do?",
    "What is the name of the act that deals with railway contracts?",
    "When was the Indian Railways Act passed?",
    "How is a gratuitous bailment terminated?",
    "Who is entitled to increase or profit from goods bailed?",
    "In the absence of any contract to the contrary, the bailee is bound to deliver to whom?",
    "Who is bound to deliver to the bailor any increase or profit which may have accrued from the goods bailed?",
    "Who is not responsible on re-delivery to bailor without title?",
    "What happens if the bailee delivers goods back to the bailor?",
    "Who is not responsible to the owner in respect of such delivery?",
    "Who may apply to the Court to stop the delivery of goods?",
    "Who has no right to sue the owner for compensation for trouble and expense voluntarily incurred by him to preserve the goods?",
    "Who can stop the delivery of the goods to the bailor?",
    "Who may retain the goods against the owner until he receives compensation?",
    "Who may sue for the return of goods lost?",
    "In the absence of a contract to the contrary, who has a right to retain goods until he receives due remuneration for the services he rendered in respect of them?",
    "What is the Indian Evidence Act, 1872?",
    "What is a general lien of bankers, factors, wharfingers, attorneys and policy-brokers?",
    "In the absence of a contract to the contrary, who may retain as security for general balance of account?",
    "What does a mercantile agent do when he is in possession of goods or the document of title to goods?",
    "What is the Limitation Act, 1963?",
    "What is a limited interest in a pledge?",
    "Where a person pledges goods in which he has limited interest, the pledge is valid to the extent of what interest?",
    "What is the Code of Civil Procedure, 1908?",
    "What is the agent bound to do when an agency is terminated by a principals death or insanity?",
    "What causes the termination of an agents authority?",
    "What are electronic records and electronic signatures in Government and its agencies?",
    "Who has power to make rules by Central Government in respect of electronic signature?",
    "What is a validity of contracts formed through electronic means?",
    "What are the functions of Controller?",
    "How does the Certifying Authority ensure compliance of the Act?",
    "What are the representations upon issuance of Digital signature Certificate called?",
    "What can invalidate proceedings of the Appellate Tribunal?",
    "What is the right to legal representation?",
    "What is a computer related offence?",
    "What is the punishment for dishonestly receiving stolen computer resource or communication device?",
    "What is one of the punishments for cyber terrorism?",
    "What is another punishment for publishing or transmitting obscene material in electronic form?",
    "What is a penalty for misrepresentation?",
    "What is the penalty for Breach of confidentiality and privacy?",
    "How is disclosure of information in breach of lawful contract punished?",
    "What are alternatives to paper-based methods of communication and storage of information called?",
    "To facilitate electronic filing of documents with the Government agencies, what act was amended?",
    "The Banker\u2019s Books Evidence Act was amended in what year?",
    "In what year was the Indian Evidence Act passed?",
    "What was the Reserve Bank of India Act passed in 1934?",
    "When was the Model Law on Electronic Commerce adopted by the United Nations?",
    "Who adopted the Model Law on Electronic Commerce?",
    "The Model Law recommends that all States give favourable consideration to what?",
    "What is the need for uniformity of the law applicable to electronic commerce?",
    "What is the need for uniformity of the law applicable to alternatives to paper-based methods of communication and storage of information?",
    "What is considered necessary to give effect to the said resolution and to promote efficient delivery of Government services by means of reliable electronic records?",
    "When was the Information Technology Act, 2000 enacted?",
    "What is the name of the Act that applies to any offence or contravention committed outside of India by any person?",
    "What does certification practice statement mean?",
    "What is an example of a form that is stored internally in the memory of the computer?",
    "What is a key pair in an asymmetric crypto system?",
    "What does a private key and its mathematically related public key mean?",
    "What is an algorithm mapping or translation of one sequence of bits into another?",
    "What makes it computationally infeasible to derive or reconstruct the original electronic record from the hash result produced by the algorithm?",
    "How many electronic records can produce the same hash result using the algorithm?",
    "Who can verify the electronic record by the use of a public key of the subscriber?",
    "The private key and the public key are unique to who and constitute a functioning key pair?",
    "What does the subscriber and constitute a functioning key pair?",
    "What may a subscriber authenticate any electronic record by using an electronic signature or electronic authentication technique?",
    "Who are the signature creation data or the authentication data linked to?",
    "What shall be filed, created or issued under Act 10 of 2009, s. 2?",
    "Who should accept, issue, create, retain and preserve any document in the form of electronic records or effect any monetary transaction in electronic records?",
    "What is the manner and format in which the [electronic signature] shall be affixed?",
    "What is necessary to give legal effect to electronic signatures?",
    "What is the validity of contracts formed through electronic means?",
    "What is sufficient to indicate to the originator that the electronic record has been received?",
    "What may the originator do if no acknowledgment has been received by the addressee?",
    "What happens when an electronic record enters a computer resource outside of the control of the originator?",
    "Where does receipt occur if the addressee has not designated a computer resource?",
    "What happens when the electronic record enters the computer resource of the addresser?",
    "What is deemed to be despatched at the place where the originator has his place of business?",
    "What is the principal place of business if the originator or the addressee has multiple places of business?",
    "What is a usual place of residence if a person does not have a place of work?",
    "What shall a person who does not have a place of business be deemed to have?",
    "What is the place where a body corporate is registered?",
    "What shall be deemed to be a secure electronic record from a specific point of time to the time of verification?",
    "What shall be deemed to be a secure electronic signature if the signature creation data was under the exclusive control of the signatory and no other person?",
    "What should employees of a Certifying Authority possess?",
    "What are the conditions subject to which the Certification Authorities shall conduct their business?",
    "What shall be distributed or used in respect of a \u201c[electronic signature] Certificate and the public key?\u201d",
    "What shall the Certifying Authorities maintain?",
    "What are the terms and conditions subject to which auditors are subject?",
    "What are the terms and conditions subject to which auditors may be appointed and the remuneration to be paid to them?",
    "What is a Certifying Authority facilitating the establishment of?",
    "What are the duties of the Certifying Authorities?",
    "Who may with the prior approval of the Central Government recognize foreign Certifying Authorities?",
    "Who may recognise a foreign Certifying Authority?",
    "What Act omitted the requirement for a controller to act as a repository?",
    "What is a licence to issue electronic signature?",
    "What does the Controller need to do to obtain a license?",
    "How many days before the date of expiry of the licence shall an application for renewal of a license be made?",
    "Who may grant or reject a licence upon receipt of an application under sub-section (/) of section 21?",
    "What may the Controller do if he is satisfied that a Certifying Authority has made a statement in or in relation to the application for the issue or renewal of a licence that is incorrect or false in material particulars?",
    "What did a person who did not comply with the terms and conditions subject to which the licence was granted fail to do?",
    "What does the Controller do if he has reasonable cause to believe that there is any ground for revoking a licence under sub-section (/)?",
    "What shall a Certifying Authority not issue during a license suspension?",
    "What Act confers powers to Income-tax authorities?",
    "What is the name of the Act that confers power to the Controller?",
    "What shall the Controller have access to if he has reasonable cause to suspect a contravention of the provisions of this Chapter?",
    "What is the purpose of having access to any computer system?",
    "What may a person in a computer system do for the purpose of searching or causing a search to be made for obtaining information or data contained in or available to such computer system?",
    "How does a Security Authority ensure the secrecy of its services?",
    "What are the security procedures to ensure that the secrecy and privacy of the electronic signatures are assured?",
    "What does the Certifying Authority do when an event has occurred or a situation has arisen that may materially and adversely affect its computer system or the conditions subject to which a [electronic signature] Certificate was granted?",
    "What shall the Certifying Authority use to notify any person who is likely to be affected by an occurrence?",
    "What is the procedure specified in its certification practice statement to deal with an event or situation?",
    "What may a person make an application to the Certifying Authority for the issue of a [electronic signature] Certificate?",
    "What is the maximum amount of the fee prescribed by the Central Government to be paid to the Certifying Authority?",
    "What shall a Certifying Authority do when issuing a Digital Signature Certificate?",
    "What is the name of the document certifying that the subscriber has accepted the Digital Signature Certificate?",
    "Who holds the private key corresponding to the subscribers private key?",
    "Who holds the private key corresponding to the public key, listed in the Digital Signature Certificate?",
    "What can be used to verify a digital signature?",
    "What can be used to verify a digital signature affixed by the private key held by the subscriber?",
    "What constitutes a functioning key pair?",
    "The Certifying Authority which has issued a Digital Signature Certificate may suspend a digital signature certificate subject to what?",
    "What happens if a material fact represented in the Digital Signature Certificate is false or has been concealed?",
    "What happened if the Certifying Authoritys private key or security system was compromised?",
    "What happens to a subscriber if the security system is compromised in a manner materially affecting the Digital Signature Certificates reliability?",
    "What shall the Certifying Authority communicate on revocation of a Digital Signature Certificate?",
    "What does the Certifying Authority publish when a Digital Signature Certificate is suspended or revoked under section 37 or section 38?",
    "Where is a notice of suspension or revocation published?",
    "What does the Certifying Authority publish in all repositories?",
    "What is the purpose of generating a key pair of digital signature certificates?",
    "Who shall be deemed to have accepted a Digital Signature Certificate if he publishes or authorises the publication of a digital signature certificate to one or more persons?",
    "What does a subscriber do by accepting a Digital Signature Certificate?",
    "Who shall exercise reasonable care to retain control of the private key corresponding to the public key?",
    "What happens if the private key corresponding to the public key listed in the Digital Signature Certificate has been compromised?",
    "What shall the subscriber be liable for until he has informed the Certifying Authority that the private key has been compromised?",
    "Who shall have jurisdiction in respect of the claim in respect to injury or damages exceeding rupee 5 crores?",
    "What is the sub-section (2) of section 58 of the Act 10 of 2009, s. 23?",
    "What section of the Indian Penal Code does Section 193 and 228 of 1860 apply to?",
    "What Act omitted staff of the Cyber Appellate Tribunal?",
    "How long does it take for a person aggrieved by an order or decision to file an appeal?",
    "What is the time limit for filing an appeal to the high court?",
    "Who is responsible for deciding a matter of fact or law arising out of an order by the appellate tribunal?",
    "What may the High Court do if it is satisfied that the appellant was prevented from filing the appeal within the said period?",
    "What is the maximum term of imprisonment for a cyber Appellate Tribunal?",
    "What is the meaning of the word fraudulently in section 66 and 67?",
    "What was section 25 of the Indian Penal Code of 1860?",
    "What is the penalty for sending offensive messages through a communication service?",
    "What is a penalty for dishonestly receiving a stolen computer resource or communication device?",
    "What is the term for a person who receives or retains a computer resource knowing or having reason to believe the same to be stolen?",
    "How long may a person be sentenced to imprisonment for a computer resource or communication device?",
    "What is the maximum fine for cheating by personation by using computer resource?",
    "How long may a person be sentenced to imprisonment for personation?",
    "When was Section 66A struck down by Supreme Courts Order?",
    "Under circumstances violating privacy, what means a person can have a reasonable expectation that he or she could disrobe in privacy?",
    "What is the penalty for lascivious behavior?",
    "How long is the term of imprisonment for a first conviction?",
    "What is the maximum amount of the fine for the second and subsequent convictions?",
    "What is the maximum fine for publishing or transmitting material depicting children in sexually explicit act?",
    "How long can a person be jailed for a sexually explicit act with a child?",
    "How long may a fine be imposed on a second conviction?",
    "What is the maximum term of imprisonment for sexually extortive acts with children?",
    "What is the maximum fine for a second or subsequent conviction?",
    "What shall be the procedure and safeguards subject to which interception or monitoring or decryption may be carried out?",
    "What is extended to provide access to or secure access to the computer resource generating, transmitting, receiving or storing such information?",
    "What does the Act do to intercept, monitor, or decrypt the information, as the case may be?",
    "Who shall be punished if he fails to assist the agency referred to in sub-section (3)?",
    "What is the name of the section that provides information stored in computer resource?",
    "What shall assist the agency referred to in sub-section (3) be punished with?",
    "What may extend to seven years and shall also be liable to fine?",
    "What does the Central Government do when it is deemed necessary or expedient to do?",
    "What is the purpose of doing what in the interest of sovereignty and integrity of India?",
    "What may the State do to prevent incitement to the commission of any cognizable offence relating to above?",
    "Who may direct any agency of the Government or intermediary to block?",
    "Who can order any agency of the Government or intermediary to block for access by the public?",
    "What are the procedures and safeguards subject to which such blocking for access is made?",
    "What is the maximum term of imprisonment for an intermediary who fails to comply with the direction issued under sub-section (/)?",
    "What is a term which may extend to seven years and also be liable to fine?",
    "What is the power to authorise to monitor and collect traffic data or information through any computer resource for cyber security?",
    "What does notification in the Official Gazette allow any agency of the Government to monitor and collect?",
    "What does the intermediary or any person in-charge or the computer resource do?",
    "Who shall provide technical assistance when called upon by the agency which has been authorised under sub-section (/)?",
    "Who shall extend all facilities to such agency to enable online access or to secure and provide online access to the computer resource?",
    "What shall an intermediary who intentionally or knowingly contravenes the provisions of sub-section (2) be punished with?",
    "What shall be the procedure and safeguards for monitoring and collecting traffic data or information?",
    "What shall a person who violates the provisions of sub-section (2) be punished with?",
    "What is a term that extends to three years and shall also be liable to a fine?",
    "What does section 43 define?",
    "What is the Indian Computer Emergency Response Team called?",
    "What is the name of the Indian Computer Emergency Response Team?",
    "How long is a term of imprisonment for a compounded offence?",
    "Under what Act is imprisonment for a term exceeding three years provided?",
    "What is 84C. Punishment for attempt to commit offences?",
    "What shall an offence to be committed and in such attempt does any act towards the commission of the offence be punished with?",
  ];
  
  export default filteredSuggestions;